import React ,{useState,useEffect} from "react";
import { Outlet } from 'react-router';
import AppSideber from '../AppSideber';

export default (isLoggedIn) => {
    const [setIsLoggedIn]=useState(true)
const handleLogout=()=>{
  setIsLoggedIn(false)
  localStorage.setItem('isLoggedIn','false')
}
useEffect(()=>{
  localStorage.setItem('isLoggedIn',isLoggedIn)
},[isLoggedIn])
    if(!isLoggedIn){
        return null;
      }
  return (
    <>
    <div className='container-fluid'>
    {isLoggedIn && <AppSideber isLoggedIn={isLoggedIn} handleLogout={handleLogout}/>}
      <Outlet />
    </div>
    
    </>
  );
};