import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import Swal from "sweetalert2";

function ChangePassword() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [playerData, setPlayerData] = useState(null);

  const [values, setValues] = useState({
    newpassword: "",
    confirmPassword: "",
  });
  const tokenData = sessionStorage.getItem("token");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { newpassword, confirmPassword } = values;
    const user = {
      email: "admin@admin.com",
      old_password: newpassword,
      new_password: confirmPassword,
    };
    await axios({
      method: "POST",
      url: `${apiBaseURL}/auth/resetPassword`,
      data: user,
      headers: { Authorization: `Bearer ${tokenData.token}` },
    })
      .then(function (response) {
        if (response.data.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Success! Thank you..",
            text: "Password updated",
            timer: 1700,
          });
          setValues({
            newpassword: "",
            confirmPassword: "",
          });
        } else {
          if (response.data.status == 401) {
            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "Oops...",
              text: `${response.data.message} !`,
              showConfirmButton: false,
              timer: 1700,
            });
            //sessionStorage.removeItem("token");
            //window.location.reload();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "Oops...",
              text: `${response.data.message} !`,
              showConfirmButton: false,
              timer: 1700,
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const fetchPlayerPoint = async () => {
    let emailId = sessionStorage.getItem("loginEmail");

    try {
      const response = await axios.post(`${apiBaseURL}/user/getPlayerPoint`, {
        email: emailId,
      });

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData && responseData.point !== undefined) {
          setPlayerData(responseData.point);
          console.log("playerData updated:", responseData.point);
        } else {
          console.log("Point data not found in response:", responseData);
        }
      } else {
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching player point:", error);
    }
  };

  //
  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  useEffect(() => {
    fetchPlayerPoint();
  }, []);
  return (
    <>
      <br />
      <p>Welcome</p>
      <span style={{ color: "blue", fontSize: "15px", marginTop: "-50px" }}>
        Gift Id
      </span>
      <p>
        Your Current Balance is{" "}
        <span style={{ color: "blue" }}>{playerData}</span>
      </p>

      <div className="row" id="pas">
        <div className="col-md-8">
          <div className="card card-outline ">
            <div className="card-header" style={{ backgroundColor: "red" }}>
              <h3 className="card-title">Changes Password</h3>
            </div>
            <div className="card-body">
              <form method="post" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-3 col-md-12">
                        <label htmlFor="newpassword">Old Password *</label>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <input
                          type="password"
                          value={values.newpassword}
                          onChange={handleChange("newpassword")}
                          name="newpassword"
                          className="inputfield form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-3 col-md-12">
                        <label htmlFor="confirmPassword">New Password *</label>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <input
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange("confirmPassword")}
                          className="inputfield form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-3 col-md-12">
                        <label htmlFor="confirmPassword">
                          Confirm New Password{" "}
                        </label>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <input
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange("confirmPassword")}
                          className="inputfield form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-3 col-md-12">
                        <label htmlFor="confirmPassword">Your Pin </label>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <input
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange("confirmPassword")}
                          className="inputfield form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <br />
                    <center>
                      <button type="button" class="btn btn-primary">
                        Change
                      </button>
                    </center>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ChangePassword;
