import React, { useState, useEffect } from "react";
// import '../../style/Contact.css'
import axios from "axios";
import Swal from "sweetalert2";
import "./pass1.css";
import Welcome from "./Welcome";
function ChangePin() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [values, setValues] = useState({
    distributor_id: "",
    password: "",
    newPassword: "",
  });
  const [value1, setValues1] = useState({
    user_id: "",
    uPassword: "",
    unewPassword: "",
  });
  const [userData, setUserData] = useState([]);
  const [destriData, setDestriData] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { user_id, uPassword, unewPassword } = value1;
    const user = {
      password: uPassword,
      roleId: 3,
      user_id,
      confirm_password: unewPassword,
    };
    await axios
      .post(`${apiBaseURL}/api/users/resetPassword`, user)
      .then(function (response) {
        if (response.data.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: response.data.message,
            showConfirmButton: true,
            timer: 1500,
          });
          setValues1({
            user_id: "",
            uPassword: "",
            unewPassword: "",
          });
        } else {
          if (response.data.status == 401) {
            sessionStorage.removeItem("token");
            window.location.reload();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "Oops...",
              text: `${response.data.message} !`,
              showConfirmButton: false,
              timer: 1700,
            });
          }
        }
      })
      .catch(function (error) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Someting Went wrong!",
          showConfirmButton: true,
          timer: 1500,
        });
      });
  };
  const handleSubmit1 = async (e) => {
    e.preventDefault();
    const { distributor_id, password, newPassword } = values;
    const user = {
      password,
      roleId: 2,
      distributor_id,
      confirm_password: newPassword,
    };
    console.log(user);
    await axios
      .post(`${apiBaseURL}/api/users/resetPassword`, user)
      .then(function (response) {
        if (response.data.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: response.data.message,
            showConfirmButton: true,
            timer: 1500,
          });
          setValues({
            password: "",
            newPassword: "",
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: response.data.message,
            showConfirmButton: true,
            timer: 1500,
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Someting Went wrong!",
          showConfirmButton: true,
          timer: 1500,
        });
      });
  };
  //get Agents
  const getUsers = async () => {
    await axios
      .get(`${apiBaseURL}/api/users/`)
      .then(function (response) {
        if (response.data.status === 200) {
          setUserData(response.data.data);
        }
      })
      .catch(function (error) {});
  };
  //get Agents
  const getAgents = async () => {
    await axios
      .get(`${apiBaseURL}/api/users/agents`)
      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          setDestriData(response.data.data);
          console.log(destriData);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
  };
  //
  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  const handleChange1 = (name) => (e) => {
    setValues1({ ...value1, [name]: e.target.value });
  };
  useEffect(() => {
    getAgents();
    getUsers();
  }, []);

  return (
    <>
      <br />
      <Welcome />
      <div className="row" id="pas">
        <div className="col-md-9">
          <div className="card card-outline " id="passcar1">
            <div
              className="card-header"
              id="passupdateheader"
              style={{ backgroundColor: "darkred" }}
            >
              <h3
                className="card-title"
                style={{ color: "white" }}
                id="passupdatetitle"
              >
                Change Your Pin
              </h3>
            </div>
            <div className="card-body" style={{ background: "silver" }}>
              <form method="post" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-4 col-md-2">
                        <label htmlFor="newpassword" id="passchlable">
                          Old Pin{" "}
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <input
                          type="password"
                          value={values.newpassword}
                          onChange={handleChange("newpassword")}
                          name="newpassword"
                          placeholder=""
                          id="passchtext"
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-4 col-md-12">
                        <label htmlFor="confirmPassword" id="passchlable">
                          New Pin{" "}
                        </label>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <input
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange("confirmPassword")}
                          placeholder=""
                          id="passchtext"
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-4 col-md-12">
                        <label htmlFor="confirmPassword" id="passchlable">
                          Confirm&nbsp;New&nbsp;Pin{" "}
                        </label>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <input
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange("confirmPassword")}
                          placeholder=""
                          id="passchtext"
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-4 col-md-12">
                        <label htmlFor="confirmPassword" id="passchlable">
                          Your Password{" "}
                        </label>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <input
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange("confirmPassword")}
                          placeholder=""
                          id="passchtext"
                        />
                      </div>
                    </div>
                    <br />
                    <center id="passbutton4">
                      <button type="button" id="passbutton1">
                        Change
                      </button>
                    </center>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ChangePin;
