import React, { useState, useEffect } from "react";

import Welcome from "./Welcome";
import "./PinPass.css";

const Pinpassuser = () => {
  const apiBaseURL = "https://royalluck.club:5000";

  const [email, setEmail] = useState(null);
  

  // const loginId = sessionStorage.getItem("loginEmail");
  
 

  



  
  return (
    <div>
      <div className="homepage">
        <Welcome />
      
       <div>
      
       </div>
      
      </div>
    </div>
  );
};

export default Pinpassuser;
