import React, { useState, useEffect } from "react";
import "../../style/Contact.css";
import axios from "axios";
import Swal from "sweetalert2";

function AddSuperMaster() {
  let apiBaseURL = "https://royalluck.club:5000";
  const agentLogin = sessionStorage.getItem("loginEmail");
  const sessionData = sessionStorage.getItem("token");
  const [values, setValues] = useState({
    first_name: "",
    password: "",
    email: "",
    role_id: 2,
  });

  const [destriData, setDestriData] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { first_name, email, password, role_id } = values;
    const user = {
      password,
      first_name,
      email,
      role_id: 2,
    };
    await axios({
      method: "post",
      url: `${apiBaseURL}/auth/adduserbyadmin1`,
      data: user,
      headers: { Authorization: `Bearer ${sessionData.token}` },
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setValues({
            // full_name: "",
            first_name: "",
            password: "",
            email: "",
            role_id: 2,
          });
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `${response.data.message} !`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          if (response.data.status == 401) {
            sessionStorage.removeItem("token");
            window.location.reload();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "Oops...",
              text: `${response.data.message} !`,
              showConfirmButton: false,
              timer: 1700,
            });
          }
        }
      })
      .catch(function (error) {
        Swal.fire(`Something went wrong!`, "error");
      });
  };
  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  useEffect(() => {
    //getAgents();
  }, []);

  return (
    <>
      <p>Welcome</p>
      <span style={{ color: "blue", fontSize: "15px", marginTop: "-50px" }}>
        {agentLogin}
      </span>
      <p>
        Your Current Balance is <span style={{ color: "blue" }}>0.00</span>
      </p>
      <div className="row">
        <div className="col-md-8">
          <div className="card card-outline ">
            <div className="card-header" style={{ backgroundColor: "red" }}>
              <h3 className="card-title">Add New Super Master</h3>
            </div>
            <div className="card-body">
              <form method="post" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-3 col-md-12">
                        <label htmlFor="staticEmail">
                          Super Master Full Name
                        </label>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <input
                          type="text"
                          required
                          className="inputfield form-control"
                          name="full_name"
                          value={values.first_name}
                          onChange={handleChange("first_name")}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-3 col-md-12">
                        <label htmlFor="staticEmail">Super Master Email</label>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <input
                          type="text"
                          required
                          className="inputfield form-control"
                          name="email"
                          value={values.email}
                          onChange={handleChange("email")}
                        />
                      </div>
                    </div>
                    <br />

                    <div className="row">
                      <div className="col-lg-3 col-md-12">
                        <label htmlFor="confirmPassword">Your Pin </label>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <input
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange("confirmPassword")}
                          className="inputfield form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <br />

                    <div className="row">
                      <div className="col-lg-3 col-md-12">
                        <label htmlFor="inputPassword">Enter Password</label>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <input
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange("password")}
                          className="inputfield form-control"
                          placeholder=""
                          required
                        />
                      </div>
                    </div>

                    <br />

                    <div className="form-group row">
                      <div className="col-sm-3 "></div>
                      <div className="col-sm-9">
                        <div className="form-group row">
                          <div className="col-sm-3 ">
                            <button className=" btn-primary form-control">
                              Reset
                            </button>
                          </div>
                          <div className="col-sm-3 ">
                            <button
                              type="onSubmit"
                              className="btn-success form-control"
                            >
                              Create
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddSuperMaster;
