import React, { useState, useEffect } from "react";
import MaterialTable, {
  MTableToolbar,
  MTableHeader,
  MTablePagination,
  MTableBodyRow,
  MTableBody,
} from "material-table";
import "../../style/Contact.css";

import "react-datepicker/dist/react-datepicker.css";
import "../points/game.css";
import axios from "axios";

import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";
// import { apiBaseURL } from "../../config";
import moment from "moment";
import { Hidden } from "@material-ui/core";
let apiBaseURL = "https://royalluck.club:5000";

// import { authToken } from "../../authToken";

function RoulletGameRecords() {
  const [data, setData] = useState([]);

  // get Agents
  const gameReports = async () => {
    await axios
      .get(`${apiBaseURL}/user/RoulletGamePlayHistory`)
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        }
      })
      .catch(function (error) {});
  };

  //get Agents

  const columns = [
    { title: "Sr.No", render: (rowData) => rowData.tableData.id + 1 },
    { title: "Draw No", field: "Draw No" },

    {
      title: "Draw Time",
      render: (rowData) =>
        moment(rowData.created).format("DD-MM-YYYY h:mm:ss "),
    },
  ];

  useEffect(() => {
    gameReports();
  }, []);

  return (
    <>
      <div className="row" id="ma1">
        <div className="card card-outline ">
          <MaterialTable
            title="Fun Roulette"
            data={data}
            columns={columns}
            options={{
              pageSize: 1,
              search: false,

              toolbar: true,
              header: true,
              thirdSortClick: false,

              headerStyle: {
                background: "red",
                position: "sticky",
                top: "0",
              },
              tableLayout: "fixed",
              maxBodyHeight: "300px",

              headerStyle: {
                background: "#b2994e",
                fontStyle: "italic",
                border: "1px solid black",
              },
              tollbarStyle: { width: "50%" },
              headerStyle: {
                whiteSpace: "nowrap",
                height: 2,
                maxHeight: 2,
                padding: 0,
                background: "#b2994e",
                border: "1px solid black",
                borderTop: "6px solid white",
                borderBottom: "6px solid white",
              },

              rowStyle: {
                width: 20,
                maxWidth: 20,
                height: 20,
                maxHeight: 20,
                padding: 0,
              },
              toolbarStyle: {
                height: 10,
                maxHeight: 10,
              },

              rowStyle: { border: "1px solid black" },
              rowStyle: (data, index) =>
                index % 2 == 1 ? { background: "#F2D2BD" } : null,
              cellStyle: { border: "1px solid black", alignItems: "center" },
              paging: false,
            }}
            components={{
              Toolbar: (props) => (
                <div style={{ background: "#b2994e", borderSpacing: "5px" }}>
                  <MTableToolbar {...props} />
                </div>
              ),
              Pagination: (props) => (
                <div style={{ backgroundColor: "#b2994e" }}>
                  <MTablePagination {...props} />
                </div>
              ),
              Row: (props) => (
                <div style={{}}>
                  <MTableBodyRow {...props} />
                </div>
              ),

              Header: (props) => (
                <div>
                  <MTableHeader {...props} />
                </div>
              ),
              Body: (props) => (
                <div style={{}}>
                  <MTableBody {...props} />
                </div>
              ),
            }}
            // muiTableHeadCellProps={{
            //   //easier way to create media queries, no useMediaQuery hook needed.
            //   sx: {
            //     fontSize: {
            //       xs: '10px',
            //       sm: '11px',
            //       md: '12px',
            //       lg: '13px',
            //       xl: '14px',
            //     },
            //   },
            // }}
          />
        </div>
      </div>
    </>
  );
}
function FunTargetRecords() {
  const [data, setData] = useState([]);

  // get Agents
  const gameReports = async () => {
    await axios
      .get(`${apiBaseURL}/user/FunTargetGamePlayHistory`)
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        }
      })
      .catch(function (error) {});
  };

  //get Agents

  const columns = [
    { title: "Sr.No", render: (rowData) => rowData.tableData.id + 1 },
    { title: "Draw No.", field: "Draw No" },

    {
      title: "Draw Time",
      render: (rowData) =>
        moment(rowData.created).format("DD-MM-YYYY h:mm:ss "),
    },
  ];

  useEffect(() => {
    gameReports();
  }, []);
  return (
    <>
      <div className="row" id="ma2">
        <div className="card card-outline">
          <MaterialTable
         
            title="Fun Target "
            data={data}
            columns={columns}
            options={{
              pageSize: 1,
              search: false,

              toolbar: true,
              header: true,
              thirdSortClick: false,

              headerStyle: {
                background: "red",
                position: "sticky",
                top: "0",
                maxWidth:"90vw",
                overflow:"auto"
              },
              
              tableLayout: "fixed",
              maxBodyHeight: "300px",

              headerStyle: {
                background: "#b2994e",
                fontStyle: "italic",
                border: "1px solid black",
              },
              tollbarStyle: { width: "50%" },
              headerStyle: {
                whiteSpace: "nowrap",
                height: 20,
                maxHeight: 20,
                padding: 0,
                background: "#b2994e",
                border: "1px solid black",
                borderTop: "6px solid white",
                borderBottom: "6px solid white",
              },

              rowStyle: {
                width: 20,
                maxWidth: 20,
                height: 20,
                maxHeight: 20,
                padding: 0,
              },
              toolbarStyle: {
                height: 10,
                maxHeight: 10,
              },

              rowStyle: { border: "1px solid black" },
              rowStyle: (data, index) =>
                index % 2 == 1 ? { background: "#F2D2BD" } : null,
              cellStyle: { border: "1px solid black", alignItems: "center" },
              paging: false,
            }}
            components={{
              Toolbar: (props) => (
                <div style={{ background: "#b2994e", borderSpacing: "5px" }}>
                  <MTableToolbar {...props} />
                </div>
              ),
              Pagination: (props) => (
                <div style={{ backgroundColor: "#b2994e" }}>
                  <MTablePagination {...props} />
                </div>
              ),
              Row: (props) => (
                <div style={{}}>
                  <MTableBodyRow {...props} />
                </div>
              ),

              Header: (props) => (
                <div>
                  <MTableHeader {...props} />
                </div>
              ),
              Body: (props) => (
                <div style={{}}>
                  <MTableBody {...props} />
                </div>
              ),
            }}
            
          />
        </div>
      </div>
    </>
  );
}

///

function TripleChanceGameRecords() {
  const [data, setData] = useState([]);

  // get Agents
  const gameReports = async () => {
    await axios
      .get(`${apiBaseURL}/user/TripleChanceGamePlayHistory`)
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        }
      })
      .catch(function (error) {});
  };

  //get Agents

  const columns = [
    { title: "Sr.No", render: (rowData) => rowData.tableData.id + 1 },
    { title: "Draw No", field: "Draw No" },

    {
      title: "Draw Time",
      render: (rowData) =>
        moment(rowData.created).format("DD-MM-YYYY h:mm:ss "),
    },
  ];

  useEffect(() => {
    gameReports();
  }, []);
  return (
    <>
      <div className="row" id="ma3">
        <div className="card card-outline">
          <MaterialTable
            title="TripleFun"
            data={data}
            columns={columns}
            options={{
              pageSize: 1,
              search: false,

              toolbar: true,
              header: true,
              thirdSortClick: false,

              headerStyle: {
                background: "red",
                position: "sticky",
                top: "0",
              },
              tableLayout: "fixed",
              maxBodyHeight: "300px",

              headerStyle: {
                background: "#b2994e",
                fontStyle: "italic",
                border: "1px solid black",
              },
              tollbarStyle: { width: "50%" },
              headerStyle: {
                whiteSpace: "nowrap",
                height: 20,
                maxHeight: 20,
                padding: 0,
                background: "#b2994e",
                border: "1px solid black",
                borderTop: "6px solid white",
                borderBottom: "6px solid white",
              },

              rowStyle: {
                width: 20,
                maxWidth: 20,
                height: 20,
                maxHeight: 20,
                padding: 0,
              },
              toolbarStyle: {
                height: 10,
                maxHeight: 10,
              },

              rowStyle: { border: "1px solid black" },
              rowStyle: (data, index) =>
                index % 2 == 1 ? { background: "#F2D2BD" } : null,
              cellStyle: { border: "1px solid black", alignItems: "center" },
              paging: false,
            }}
            components={{
              Toolbar: (props) => (
                <div style={{ background: "#b2994e", borderSpacing: "5px" }}>
                  <MTableToolbar {...props} />
                </div>
              ),
              Pagination: (props) => (
                <div style={{ backgroundColor: "#b2994e" }}>
                  <MTablePagination {...props} />
                </div>
              ),
              Row: (props) => (
                <div style={{}}>
                  <MTableBodyRow {...props} />
                </div>
              ),

              Header: (props) => (
                <div>
                  <MTableHeader {...props} />
                </div>
              ),
              Body: (props) => (
                <div style={{}}>
                  <MTableBody {...props} />
                </div>
              ),
            }}
          />
        </div>
      </div>
    </>
  );
}
function Gamerecords() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setShow(false);
  // get Agents
  const gameReports = async () => {
    await axios
      .get(`${apiBaseURL}/user/Gamerecords`)
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        }
      })
      .catch(function (error) {});
  };

  //get Agents

  const columns = [
    { title: "Sr.No", render: (rowData) => rowData.tableData.id + 1 },
    { title: "Draw No.", field: "Draw No" },

    {
      title: "Draw Time",
      render: (rowData) =>
        moment(rowData.created).format("DD-MM-YYYY h:mm:ss "),
    },
  ];

  useEffect(() => {
    gameReports();
  }, []);
  function refreshPage() {
    window.location.reload();
  }
  return (
    <>
      <div className="row" id="ma4">
        <div className="card card-outline ">
          <MaterialTable
            title="Fun AndarBahar "
            data={data}
            columns={columns}
            options={{
              pageSize: 1,
              search: false,

              toolbar: true,
              header: true,
              thirdSortClick: false,

              headerStyle: {
                background: "red",
                position: "sticky",
                top: "0",
              },
              tableLayout: "fixed",
              maxBodyHeight: "300px",

              headerStyle: {
                background: "#b2994e",
                fontStyle: "italic",
                border: "1px solid black",
              },
              tollbarStyle: { width: "50%" },
              headerStyle: {
                whiteSpace: "nowrap",
                height: 5,
                maxHeight: 5,
                padding: 0,
                background: "#b2994e",
                border: "1px solid black",
                borderTop: "6px solid white",
                borderBottom: "6px solid white",
              },

              rowStyle: {
                width: 10,
                maxWidth: 10,
                height: 5,
                maxHeight: 5,
                padding: 0,
              },
              toolbarStyle: {
                height: 10,
                maxHeight: 10,
              },

              rowStyle: { border: "1px solid black" },
              rowStyle: (data, index) =>
                index % 2 == 1 ? { background: "#F2D2BD" } : null,
              cellStyle: { border: "1px solid black", alignItems: "center" },
              paging: false,
            }}
            components={{
              Toolbar: (props) => (
                <div style={{ background: "#b2994e", borderSpacing: "5px" }}>
                  <MTableToolbar {...props} />
                </div>
              ),
              Pagination: (props) => (
                <div style={{ backgroundColor: "#b2994e" }}>
                  <MTablePagination {...props} />
                </div>
              ),
              Row: (props) => (
                <div style={{}}>
                  <MTableBodyRow {...props} />
                </div>
              ),

              Header: (props) => (
                <div>
                  <MTableHeader {...props} />
                </div>
              ),
              Body: (props) => (
                <div style={{}}>
                  <MTableBody {...props} />
                </div>
              ),
            }}
          />
        </div>
      </div>

      <div className="row" id="but">
        <Button onClick={() => setLgShow(true)} id="button7">
          Refresh
        </Button>
      </div>
      <></>

      <Modal
        size="xl"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton style={{ background: "blue" }}>
          <Modal.Title>
            <div class="row" id="titl">
              <div class="col-6" id="titl1">
                <h6>MESSAGE FROM ROYALLUCK</h6>
              </div>
              <div class="col-6 " id="titl2">
                <h6>फनरेप की तरफ से सन्देश</h6>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-6 border-end" id="titl3">
              <h5 style={{ color: "#800000" }}>Dearest Customer,</h5>
              <h6>
                It has come to our attention that there are many duplicate
                versions of our software running in the market.
                <br />
                <br />
                Many innocent players have been cheated by them. So Kindly
                remember the url :{" "}
                <a href="royalluck.vip">"www.royalluck.vip/results.aspx"</a> &
                always check the last 5 Draw No's on this site from the one
                which is shown on the actual game.
                <br />
                <br />
                If the Numbers do not match then you would know that you are not
                playing on the original royalluck Website.Please be alert and do
                this check everytime.
                <br />
              </h6>
              <h6 style={{ color: "black" }}>Regards royalluck</h6>
            </div>
            <div class="col-6" id="tit4">
              <h5 style={{ color: "#800000" }}>प्रिय ग्राह्क</h5>

              <h6>
                हमे यह पता चला हे की बाजार मे हमारे जैसे नकली गेम चल रहे हे ।{" "}
                <br />
                <br />
                इन नकली गेम की वजह बहुत सारे लोगों के साथ धोखा हो रहा हे। इस
                समस्या के समाधान के लिए हमारे दिए हुए इस लिंक:{" "}
                <a href="royalluck.vip">"www.royalluck.vip/results.aspx"</a> पे
                जाकर आखिरी ५ ड्रा नम्बर को मिला ले जो आपके द्वारा खेले जा रहे
                हे।यदि ये नम्बर ना मिले तो समझ जाये की आप फनरेप के गेम नही खेल
                रहे है।
                <br />
                <br />
                और आपके साथ धोखा हो रहा है। कृपया सावधान रहे और गेम के नम्बर
                मिला ले। <br />
                <br />
                आभारी फनरेप <br />
                <a href="" variant="secondary" id="cl" onClick={handleClose}>
                  Close
                </a>
              </h6>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export {
  Gamerecords,
  FunTargetRecords,
  RoulletGameRecords,
  TripleChanceGameRecords,
};
