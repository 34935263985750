import React, { useState, useEffect } from "react";
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "../style/Login.css";
import axios from "axios";
import { apiBaseURL } from "../config";
import PropTypes from "prop-types";
import img1 from "../images/login-box.png";
import img2 from "../images/im1.png";
import ApkFile from "../assets/files/royalluck.apk";
import windowimage from "../assets/windows.png";
import androidimage from "../assets/androidd.png";
import Swal from "sweetalert2";

const Login = ({ setToken }) => {
  const [email, setEmail] = useState([]);
  const [, setError] = useState("");
  const agentLogin = sessionStorage.getItem("loginEmail");
  const [loginEmail, setloginEmail] = useState("");
  const [values, setValues] = useState({
    email: "",
    password: "",
    captcha: "",
  });
 


  const [data, setData] = useState([]);
  const [error, seterrorMsg] = useState(false);
  const [isBlinking, setIsBlinking] = useState(false);
  const [captchaNumber, setCaptchaNumber] = useState(generateRandomCaptcha());
  const [captchaText, setCaptchaText] = useState("");
  const [words, setWords] = useState([]);
  // const [words, setWords] = useState([]);
  const [captcha, setCaptcha] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  // const [captchaImage, setCaptchaImage] = useState('');
  const [expectedCaptchaAnswer, setExpectedCaptchaAnswer] = useState("");
  // const [loginMessage, setLoginMessage] = useState('');
  function generateRandomCaptcha() {
    const min = 10000000;
    const max = 89999999;
    let randomNumber = Math.floor(min + Math.random() * (max - min + 1));

    return randomNumber;
  }
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = values;
    const user = { email, password };

    if (captchaText !== captchaNumber.toString()) {
      seterrorMsg("captcha verification failed");
      return;
    }

    if (!email || !password || !captchaText) {
      // Successful login
      console.log("Login successful!");
      // Redirect the user to the desired page
      // You can use React Router for page navigation
    } else {
      // Failed login
      setErrorMessage("Please provide both Email and Password and captcha");
    }
   
    try {
      const roleIdResponse = await axios.post(`${apiBaseURL}/auth/getRoleId`, { email });
      const roleId = roleIdResponse.data.roleId;
       console.log("this is login.js rollID    " + roleId )
      sessionStorage.setItem("roleId", roleId);
      
      // Continue with the rest of your logic using the roleId if needed
    } catch (error) {
      console.log("Error retrieving roleId:", error);
    }




    try {
      const response = await axios.post(`${apiBaseURL}/UserSupMasLogin`, user);
      sessionStorage.setItem("loginEmail", email);

      // sessionStorage.setItem("loginEmail", email);
      if (response.data.status === 200) {
        setToken(response.data.token);
        seterrorMsg(response.data.message);
      } else {
        seterrorMsg(response.data.message);
        sessionStorage.removeItem("loginEmail");
      }
    } catch (error) {
      // history.push("/login");
      console.log("Error occurred:", error);
      sessionStorage.removeItem("loginEmail");
    }
   
  };

  // const [email, setEmail] = React.useState("GK");
  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
    //   const { value } = e.target;
    //  if (value.startsWith("GK")) {
    //   setEmail(value);
    // }
  };

  function handleRefreshCaptcha() {
    setCaptchaNumber(generateRandomCaptcha());
    setCaptchaText("");
  }

  const handleButtonClick = () => {
    setIsBlinking(true);
    setTimeout(() => {
      setIsBlinking(false);
    }, 150);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newWords = [
        "Royal Luck",
        "Triple Chance",
        "Roulette",
        "Fun Target Timer",
        "Andar Bahar",
        "7 Up & Down",
      ];
      const randomIndex = Math.floor(Math.random() * newWords.length);
      const updatedWords = [...words, newWords[randomIndex]];
      setWords(updatedWords);
      localStorage.setItem("words", JSON.stringify(updatedWords));
    }, 1000);

    const storedWords = localStorage.getItem("words");
    if (storedWords) {
      setWords(JSON.parse(storedWords));
    }

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div>
        {<div className="animate">{words[words.length - 1]}</div>}

        {
          <div className="downloadExeButton">
            <DownloadExe />
          </div>
        }
        {
          <div className="downloadApkButton">
            <DownloadApk />
          </div>
        }
        <div className="homepageui">
          <div className="top-bar">
            <div className="gridContainer clearfix">
              <div id="LayoutDiv1">
                <div className="header"></div>

                <div className="homelogo">
                  <img
                    id="homelogo1"
                    src={require("../images/logo5-removebg-preview.png")}
                    alt="logo"
                    style={{ marginTop: "-120px", position: "relative" }}
                  ></img>
                </div>

                <div className="login-div">
                  
                    <div className="login-row login-row-width">
                      <h4 className="stylish-heading">Royal Luck</h4>
                    </div>
                    <div className="form1">
                      <form onSubmit={handleSubmit} id="form1">
                        <div className="form2">
                          <div class="input-group" id="username">
                            <input
                              maxLength="20"
                              // value={email}
                              onChange={handleChange("email")}
                              name="email"
                              type="text"
                              id="email"
                              variant="standard"
                              required
                            />
                            <label for="">Email or Username</label>
                          </div>
                          <div>
                            <span
                              id="reqUserID"
                              style={{ color: "Red", visibility: "hidden" }}
                            ></span>
                          </div>
                          <div class="input-group" id="password">
                            <input
                              maxLength="20"
                              name="password"
                              id="password"
                              value={values.password}
                              onChange={handleChange("password")}
                              type="password"
                              variant="standard"
                              required
                            />
                            <label for="">password</label>
                          </div>
                          <div>
                            <span
                              id="reqCaptcha"
                              style={{ color: "Red", visibility: "hidden" }}
                            ></span>
                          </div>

                          <div class="row" id="homecap12">
                            <div class="col">
                              <div class="input-group1">
                                <input
                                  name="txtCaptcha"
                                  type="text"
                                  maxLength="8"
                                  value={captchaText}
                                  onChange={(e) =>
                                    setCaptchaText(e.target.value)
                                  }
                                  required
                                />
                                <label for="">captcha</label>
                              </div>
                            </div>
                            <div class="col" id="logcaptcha">
                              <span class="input-group2">{captchaNumber}</span>
                              <input
                                type="image"
                                name="imgBtnRefreshCaptcha"
                                id="imgBtnRefreshCaptcha"
                                src={img2}
                                alt="Refresh Captcha"
                                className="button-point"
                                onClick={handleRefreshCaptcha}
                              />
                            </div>
                          </div>

                          <div className="homebutton1">
                            <input
                              type="submit"
                              name="btnLogin"
                              value="SIGN IN"
                              id="btnLogin"
                              onClick={handleButtonClick}
                              style={{
                                animation: isBlinking
                                  ? "blink 1s infinite"
                                  : "none",
                              }}
                            />
                            <style>
                              {`
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}
                            </style>
                          </div>
                        </div>
                        {/* <table >
            <tbody>
              <tr >
              <td id="lab">Email or Username</td>
              <td align="right">

                  <input
                     className="txt-login"
                     id="txtemail"
                     maxLength="20"
                   
                    // value={email}
                    onChange={handleChange('email')}
                    name='email'
                    type='text'
                    
                    variant="standard"
                    required
                  />
                </td>
                                <td>
                                <span id="reqUserID" style={{ color: 'Red', visibility: 'hidden' }}></span>
                 
                  </td>
                </tr>
              <tr id="lab">
              <td id="lab">Password</td>
              <td align="right">
                  <input
                    className="txt-login"
                    id="txtPassword"
                    
                    name='password'
                    value={values.password}
                    onChange={handleChange('password')}
                    type='password'
                    variant="standard"
                   
                  />
                 </td>
                <td>
                <span id="reqPassword" style={{ color: 'Red', visibility: 'hidden' }}></span>
                </td>
              </tr>
<tr>
              <td id="lab">Enter&nbsp;Below Number</td>

               <td align="right">
                  <input
                    name="txtCaptcha"
                    type="text"
                    maxLength="8"
                    id="txtCaptcha"
                    className="txt-login"
                    value={captchaText}
            onChange={(e) => setCaptchaText(e.target.value)}
                    
                  />
                  
                </td>
                </tr>
                
                <td>
                <span id="reqCaptcha" style={{ color: 'Red', visibility: 'hidden' }}></span>
                </td>
              
                <tr id="bc">
               <td colSpan="3" align="center" id="bc">
               <div id="updPnlCaptcha">
                    <div className="updPnlCaptcha">
                    

                      
                      <input type="text"
                        id="imgCaptcha"
                        disabled
                        
                        value={captchaNumber}
                       
                      
                       
                      />
                      <input
                        type="image"
                        name="imgBtnRefreshCaptcha"
                        id="imgBtnRefreshCaptcha"
                        src={img2}
                        alt="Refresh Captcha"
                        className='button-point'
                        onClick={handleRefreshCaptcha}
                      />
                      <input
        type="submit"
        name="btnLogin"
        value="SIGN IN"
        id="btnLogin"
        onClick={handleButtonClick}
        style={{
          animation: isBlinking ? "blink 1s infinite" : "none"
        }}
      />
      <style>
        {`
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}
      </style>
                    </div>
                    
                  </div>
                 
                </td> 
                
</tr>
                
                    
                
            
                
                  
                </tbody>
          </table> */}
                      </form>
                   </div>
                    <div className="my-5">
                      {error ? (
                        <div className="alert alert-warning" role="alert">
                          {error ? error : ""}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// function DownloadExe() {
//   return (
//     <a href={ApkFile} download="Royalluck">
//       <button className="DownloadExeButton">
//         {" "}
//         <img src={windowimage} alt="Windows Logo" class="icon"></img>Download
//         .Exe
//       </button>
//     </a>
//   );
// }
// function DownloadApk() {
//   return (
//     <a href={ApkFile} download="Royalluck">
//       <button className="DownloadApkButton">
//         <img src={androidimage} alt="Windows Logo" class="apkicon"></img>
//         Download .Apk
//       </button>
//     </a>
//   );
// }
function DownloadExe() {
  const exeUrl = 'https://royalluck.club/royalluckapk/windowsbuild.zip';

  const handleDownload = () => {
    window.location.href = exeUrl;
  };

  return (
    <div className="downloadExeButton">
      <button className="DownloadExeButton" onClick={handleDownload}>
        <img src={windowimage} alt="Windows Logo" className="icon" />
        Download .Exe
      </button>
    </div>
  );
}

function DownloadApk() {
  const apkUrl = 'https://royalluck.club/royalluckapk/royalluck.apk'; 

  const [apkFile, setApkFile] = useState(apkUrl);

  const handleDownload = () => {
    window.location.href = apkFile;
  };

  return (
    <div className="downloadApkButton">
      <button className="DownloadApkButton" onClick={handleDownload}>
        <img src={androidimage} alt="Android Logo" className="apkicon" />
        Download .Apk
      </button>
    </div>
  );
}


Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
export default Login;
