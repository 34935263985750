import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
   // const userToken = JSON.parse(tokenString);
    return tokenString
  };
 

  const [token, setToken] = useState(getToken());
 
  
  const saveToken = Token => {
    sessionStorage.setItem('token', Token);
    setToken(Token);
  };
  

  return {
    setToken: saveToken,
   
    token,
   
  }
}