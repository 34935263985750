import React from 'react'
import "./Nav.css"
const NavTop = () => {
  return (
    <div className='navTop' id="funtop">
        <div className='containew-fluid' id="funtop1" style={{backgroundColor:'rgb(31, 97, 141)'}}></div>
       
    </div>
  )
}

export default NavTop