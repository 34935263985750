import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "./pass.css";
import Welcome from "./Welcome";

function ChangeUserPassword() {
  let apiBaseURL = "https://royalluck.club:5000";
  const emailId = sessionStorage.getItem("loginEmail");

  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { oldPassword, newPassword, confirmPassword } = values;

    const user = {
      email: emailId,
      oldPassword,
      newPassword,
      confirmPassword,
    };

    await axios
      .post(`${apiBaseURL}/user/resetUserPassword`, user)
      .then(function (response) {
        if (response.data.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: response.data.message,
            showConfirmButton: true,
            timer: 1500,
          });
          setValues({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        } else {
          if (response.data.status === 401) {
            sessionStorage.removeItem("token");
            window.location.reload();
          } else {
            Swal.fire({
              position: "top-end",
              id:"errpass1",
              icon: "warning",
              title: "Oops...",
              text: `${response.data.message} !`,
              showConfirmButton: false,
              timer: 1700,
            });
          }
        }
      })
      .catch(function (error) {
        Swal.fire({
          position: "top-end",
          id:"errpass1",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: true,
          timer: 1500,
        });
      });
  };

  return (
    <>
      <br />
      <Welcome />
      <div className="row" id="pas">
        <div className="col-md-9">
          <div className="card card-outline " id="car1">
            <div
              className="card-header"
              style={{ backgroundColor: "darkred" }}
              id="updateheader"
            >
              <h3
                className="card-title"
                style={{ color: "white" }}
                id="updatetitle"
              >
                Change Password
              </h3>
            </div>
            <div className="card-body" style={{ background: "silver" }}>
              <form method="post" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row" id="chustable1">
                      <div className="col-lg-4 " >
                        <label htmlFor="oldPassword" id="chlable">
                          Old Password
                        </label>
                      </div>
                      <div className="col-lg-4">
                        <input
                          type="password"
                          value={values.oldPassword}
                          onChange={handleChange("oldPassword")}
                          name="oldPassword"
                          placeholder=""
                          id="chtext"
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row" id="chustable2">
                      <div className="col-lg-4 ">
                        <label htmlFor="newPassword" id="chlable">
                          New Password
                        </label>
                      </div>
                      <div className="col-lg-4 ">
                        <input
                          type="password"
                          name="newPassword"
                          value={values.newPassword}
                          onChange={handleChange("newPassword")}
                          placeholder=""
                          id="chtext"
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row" id="chustable3">
                      <div className="col-lg-4 col-md-12">
                        <label htmlFor="confirmPassword" id="chlable">
                          Confirm New Password
                        </label>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <input
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange("confirmPassword")}
                          placeholder=""
                          id="chtext"
                        />
                      </div>
                    </div>
                    <br />
                    <center id="button4">
                      <button type="submit" id="button1">
                        Change
                      </button>
                    </center>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeUserPassword;
