import React, { useState, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import axios from "axios";
import moment from "moment";

function DailyStatus() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [data, setData] = useState([]);
  const columns = [
    { title: "Serial No", render: (rowData) => rowData.tableData.id + 1 },
    { title: "Location", field: "Location" },
    { title: "Agent Id", field: "Agent_id" },

    { title: "Profit", field: "Profit", filtering: true },
  ];
  //get Agents
  const getReports = async () => {
    await axios({
      method: "GET",
      url: `${apiBaseURL}/user/DailyStatus`,
      // data: user,
      // headers: {"Authorization" : `Bearer ${authToken}`}
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getReports();
  }, []);

  return (
    <>
      <div
        className="card card-outline "
        style={{
          marginLeft: "20px",
          width: "500px",
          height: "20px",
          marginLeft: "200px",
          marginTop: "50px",
        }}
      >
        <MaterialTable
          title="Daily Status"
          data={data}
          columns={columns}
          options={{
            actionsColumnIndex: -1,
            headerStyle: { background: "#b2994e", fontStyle: "italic" },
            rowStyle: (data, index) =>
              index % 2 == 1 ? { background: "#F2D2BD" } : null,
          }}
          components={{
            Toolbar: (props) => (
              <div style={{ background: "#b2994e" }}>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
        />
      </div>
    </>
  );
}
export default DailyStatus;
