import React from 'react'


import "../style/LogOut.css"
import { Link } from "react-router-dom"
const LogOut = () => {

  const logoutUser = async () =>{
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("loginEmail");
    sessionStorage.removeItem("roleId");
    window.location.href='/';
    window.location.reload();
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  }

  return (



    <div className="homepage">
    <div className='logout'>

      <div className="row" id="logout">
        <h6 >You have Logout of the royalluck ! Network.</h6>
      </div>

      <div className="row ">

        <div class="col-12 "> 
        <Link  className="nav-link" onClick={() => logoutUser()} data-toggle="dropdown" to="/">
          <span id="btn9" >Return to www.royalluck.pro</span>
        </Link></div>







      </div>
      <div className="row ">
        <div class="col-12 ">  
        <Link className="nav-link" onClick={() => logoutUser()} data-toggle="dropdown" to="/">
          <span id="btn10"> Click Here To Close</span>
        </Link></div>
      </div>
    </div>
</div>
  )
}

export default LogOut






// import React from 'react';
// import { useHistory } from 'react-router-dom';

// const Logout = () => {
//   const history = useHistory();

//   const handleLogout = () => {
//     // Perform logout actions, e.g., sending request to server
//     history.push('/'); // Redirect to login page after logout
//   };

//   return (
//     <div>
//       <h2>Logout</h2>
//       <button onClick={handleLogout}>Logout</button>
//     </div>
//   );
// };

// export default Logout;

