import React, { useState, useEffect } from "react";
import MaterialTable, {
  MTableToolbar,
  MTableHeader,
  MTablePagination,
  MTableBodyRow,
  MTableCell,
  MTableBody,
} from "material-table";
import axios from "axios";
import moment from "moment";

function GameReport() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [data, setData] = useState([]);
  const columns = [
    { title: "Room id", render: (rowData) => rowData.tableData.id + 1 },
    { title: "Joker Card no.", field: "user_id" },
    { title: "Created", field: "amount", filtering: true },
  ];
  //get Agents
  const getReports = async () => {
    await axios({
      method: "GET",
      url: `${apiBaseURL}/user/GameReport`,
      // data: user,
      // headers: {"Authorization" : `Bearer ${authToken}`}
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getReports();
  }, []);

  return (
    <>
      <div
        className="card card-outline "
        style={{
          width: "500px",
          height: "10px",
          marginLeft: "200px",
          marginTop: "50px",
        }}
      >
        <MaterialTable
          title="Game Report"
          data={data}
          columns={columns}
          options={{
            pageSize: 500,
            search: false,

            toolbar: true,
            header: true,
            thirdSortClick: false,

            headerStyle: {
              background: "red",
              position: "sticky",
              top: "0",
            },
            tableLayout: "fixed",
            maxBodyHeight: "300px",

            headerStyle: {
              background: "#b2994e",
              fontStyle: "italic",
              border: "1px solid black",
            },
            padding: "dense",
            cellStyle: {
              whiteSpace: "nowrap",
            },
            tollbarStyle: { width: "50%" },
            headerStyle: {
              whiteSpace: "nowrap",
              height: 20,
              maxHeight: 20,
              padding: 0,
              background: "#b2994e",
              border: "1px solid black",
              borderTop: "6px solid white",
              borderBottom: "6px solid white",
            },

            rowStyle: {
              width: 20,
              maxWidth: 20,
              height: 20,
              maxHeight: 20,
              padding: 0,
            },
            toolbarStyle: {
              height: 10,
              maxHeight: 10,
            },

            rowStyle: { border: "1px solid black" },
            rowStyle: (data, index) =>
              index % 2 == 1 ? { background: "#F2D2BD" } : null,
            cellStyle: { border: "1px solid black", alignItems: "center" },
            paging: false,
          }}
          components={{
            Toolbar: (props) => (
              <div style={{ background: "#b2994e", borderSpacing: "5px" }}>
                <MTableToolbar {...props} />
              </div>
            ),
            Pagination: (props) => (
              <div style={{ backgroundColor: "#b2994e" }}>
                <MTablePagination {...props} />
              </div>
            ),
            Row: (props) => (
              <div style={{}}>
                <MTableBodyRow {...props} />
              </div>
            ),

            Header: (props) => (
              <div>
                <MTableHeader {...props} />
              </div>
            ),
            Body: (props) => (
              <div style={{}}>
                <MTableBody {...props} />
              </div>
            ),
          }}
        />
      </div>
    </>
  );
}
export default GameReport;
