import React, { useState, useEffect } from "react";
import "./use.css";
import axios from "axios";

import Welcome from "../settings/Welcome";
const UpdateProfile = ({onUpdate}) => {
 
  let apiBaseURL = "https://royalluck.club:5000";
  const [userData, setUserData] = useState({
    full_name:"",
    first_name: "",
    last_name: "",
    phone: "",
    date_of_bith: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    address: "",
    occupation:"",
    memberType:"",
    email: "",
  });
  const agentLogin = sessionStorage.getItem("loginEmail");
  let emailId = sessionStorage.getItem("loginEmail");

  useEffect(() => {
    axios
      .get(`${apiBaseURL}/user/getProfile/${emailId}`)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [emailId]);


  
  


  const handleChange = (e) => {
    const {name,value}=e.target;

    setUserData({ ...userData, [name]:value });
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();
    // onUpdate(userData)
    axios
      .put(`${apiBaseURL}/user/updateUsers/${emailId}`, userData)
      .then((response) => {
        console.log(response.data);
        alert("Profile updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
      });
  };

  const handleResetClick = () => {
    setUserData((prevData) => ({
      ...prevData,
      full_name: "",
    }));
  };

  return (
    <div>
      <div className="homepage">
      <Welcome />
     
      <div className="row">
        <div className="col-8" id="ca1">
          <div className="card ">
            <div
              className="card-header"
              style={{ backgroundColor: "darkred" }}
              id="updateheader"
            >
              <h3
                className="card-title"
                style={{ color: "white" }}
                id="updatetitle"
              >
                Update Profile
              </h3>
            </div>
            
            <div
              className="card-body"
              id="car2"
              style={{ background: "#E5E4E2" }}
            >
              
              <form method="post" onSubmit={handleUpdateClick}>
                <div className=" row" id="uptable">
                  <label
                    htmlFor="staticEmail"
                    className="col-3 "
                    id="userlable"
                  >
                    Full Name:
                  </label>
                  <div className="col-6" id="usertext">
                    <input
                       type="text"
                       id="upusertext"
                       name="full_name"
                       value={userData.full_name}
                       onChange={handleChange}
                       required
                       />
                  </div>
                </div>
                <div className=" row" id="uptable1">
                  <label
                    htmlFor="staticEmail"
                    className="col-3 "
                    id="userlable"
                  >
                    First Name:
                  </label>
                  <div className="col-6" id="usertext">
                    <input
                       type="text"
                       id="upusertext"
                       name="first_name"
                       value={userData.first_name}
                       onChange={handleChange}
                      
                       />
                  </div>
                </div>
                <div className=" row" id="uptable2">
                  <label
                    htmlFor="staticEmail"
                    className="col-3 "
                    id="userlable"
                  >
                    Last Name:
                  </label>
                  <div className="col-6" id="usertext">
                    <input
                      type="text"
                      required
                      id="upusertext1"
                      placeholder="ID"
                      disabled
                      name="last_name"
                      value={agentLogin}
                    />
                  </div>
                </div>
                <div className="row" id="uptable3">
                  <label htmlFor="inputPhone" className="col-3 " id="userlable">
                    Phone:
                  </label>
                  <div className="col-6" id="usertext">
                    <input
                      type="number"
                      name="phone"
                      id="upusertext2"
                      value={userData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
               
               
               
                <div className=" row" id="uptable4">
                  <label
                    htmlFor="staticEmail"
                    className="col-3 "
                    id="userlable"
                  >
                    Date Of Birth:
                  </label>
                  <div className="col-6" id="usertext">
                    <select id="name2">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </select>
                    <select id="name2">
                      <option value="JAN">JAN</option>
                      <option value="FEB">FEB</option>
                      <option value="MAR">MAR</option>
                      <option value="APR">APR</option>
                      <option value="MAY">MAY</option>
                      <option value="JUN">JUN</option>
                      <option value="JUL">JUL</option>
                      <option value="AUG">AUG</option>
                      <option value="SEP">SEP</option>
                      <option value="OCT">OCT</option>
                      <option value="NOV">NOV</option>
                      <option value="DEC">DEC</option>
                    </select>
                    <select id="name2">
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                      <option value="1999">1999</option>
                      <option value="1998">1998</option>
                      <option value="1997">1997</option>
                      <option value="1996">1996</option>
                      <option value="1995">1995</option>
                      <option value="1994">1994</option>
                      <option value="1993">1993</option>
                      <option value="1992">1992</option>
                      <option value="1991">1991</option>
                      <option value="1990">1990</option>
                      <option value="1989">1989</option>
                      <option value="1988">1988</option>
                      <option value="1987">1987</option>
                      <option value="1986">1986</option>
                      <option value="1985">1985</option>
                      <option value="1984">1984</option>
                      <option value="1983">1983</option>
                      <option value="1982">1982</option>
                      <option value="1981">1981</option>
                      <option value="1980">1980</option>
                      <option value="1979">1979</option>
                      <option value="1978">1978</option>
                      <option value="1977">1977</option>
                      <option value="1976">1976</option>
                      <option value="1975">1975</option>
                      <option value="1974">1974</option>
                      <option value="1973">1973</option>
                      <option value="1972">1972</option>
                      <option value="1971">1971</option>
                      <option value="1970">1970</option>
                      <option value="1969">1969</option>
                      <option value="1968">1968</option>
                      <option value="1967">1967</option>
                      <option value="1966">1966</option>
                      <option value="1965">1965</option>
                      <option value="1964">1964</option>
                      <option value="1963">1963</option>
                      <option value="1962">1962</option>
                      <option value="1961">1961</option>
                      <option value="1960">1960</option>
                      <option value="1959">1959</option>
                      <option value="1958">1958</option>
                      <option value="1957">1957</option>
                      <option value="1956">1956</option>
                      <option value="1955">1955</option>
                      <option value="1954">1954</option>
                      <option value="1953">1953</option>
                      <option value="1952">1952</option>
                      <option value="1951">1951</option>
                      <option value="1950">1950</option>
                    </select>
                  </div>
                </div>

                <div className=" row" id="uptable5">
                  <label
                    htmlFor="staticEmail"
                    className="col-3 "
                    id="userlable"
                  >
                    Country:
                  </label>
                  <div className="col-6" id="usertext">
                    <select id="name3" name="country" value={userData.country} onChange={handleChange}>
                     
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Albanie">Albanie</option>
                      <option value="Algérie">Algérie</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="	Angola"> Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="	Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>

                      <option value="Argentina">Argentina</option>
                      <option value="	Armenia"> Armenia</option>

                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="	Bahrain"> Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="	Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>

                      <option value="	Belgium"> Belgium</option>
                      <option value="	Belize"> Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">
                        Bosnia and Herzegovina
                      </option>
                      <option value="	Botswana"> Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="	Brunei Darussalam">
                        {" "}
                        Brunei Darussalam{" "}
                      </option>
                      <option value="Bulgaria">Bulgaria</option>

                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="	Burundi"> Burundi</option>

                      <option value="	Cambodia"> Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="	Cayman Islands"> Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="		Christmas Island">
                        {" "}
                        Christmas Island
                      </option>
                      <option value="Cocos (Keeling) Islands">
                        Cocos (Keeling) Islands
                      </option>
                      <option value="	Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="	Congo"> Congo</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Côte D'ivoire">Côte D'ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="	Cuba"> Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="	Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="		Dominica"> Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>

                      <option value="East Timor">East Timor</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="	Eritrea"> Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="	Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands">Falkland Islands</option>

                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="	Fiji"> Fiji</option>

                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Territories">
                        French Southern Territories
                      </option>
                      <option value="	Gabon"> Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="	Georgia">Georgia</option>
                      <option value="Germany">Germany</option>

                      <option value="	Ghana"> Ghana</option>
                      <option value="	Gibraltar"> Gibraltar</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="	Botswana"> Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="	Brunei Darussalam">
                        {" "}
                        Brunei Darussalam{" "}
                      </option>
                      <option value="Guatemala">Guatemala</option>

                      <option value="Guinea">Guinea</option>
                      <option value="	Guinea-Bissau"> Guinea-Bissaui</option>

                      <option value="Guyana">Guyana</option>
                      <option value="india">india</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="staticEmail"
                    className="col-3 "
                    id="userlable"
                  >
                    State:
                  </label>
                  <div className="col-6" id="usertext">
                    <select id="name3" name="state" value={userData.state} onChange={handleChange}>
                      <option value="A">A</option>
                      <option value="	Andhra Pradesh"> Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="	Assam"> Assam</option>
                      <option value="B">B</option>
                      <option value="	Bihar"> Bihar</option>
                      <option value="C">C</option>
                      <option value="		Chhattisgarh"> Chhattisgarh</option>
                      <option value="G">G</option>
                      <option value="	Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>

                      <option value="H">H</option>
                      <option value="	Haryana"> Haryana</option>

                      <option value="	Himachal Pradesh">
                        {" "}
                        Himachal Pradesh
                      </option>
                      <option value="	J"> J</option>
                      <option value="	Jammu and Kashmir">
                        {" "}
                        Jammu and Kashmir
                      </option>
                      <option value="	Jharkhand"> Jharkhand</option>
                      <option value="	K"> K</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="	M"> M</option>
                      <option value="		Madhya Pradesh"> Madhya Pradesh</option>
                      <option value="	Maharashtra"> Maharashtra</option>
                      <option value="		Manipur"> Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>

                      <option value="	Mizoram"> Mizoram</option>
                      <option value="	N"> N</option>
                      <option value="		Nagaland"> Nagaland</option>
                      <option value="	O"> O</option>
                      <option value="	Odisha"> Odisha</option>
                      <option value="	P"> P</option>
                      <option value="	Punjab"> Punjab</option>
                      <option value="	R"> R</option>
                      <option value="	Rajasthan"> Rajasthan</option>
                      <option value="	S"> S</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="	T"> T</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="		Telangana"> Telangana</option>
                      <option value="	Tripura"> Tripura</option>
                      <option value="	U"> U</option>
                      <option value="Uttar Pradesh"> Uttar Pradesh </option>
                      <option value="	Uttarakhand"> Uttarakhand</option>
                      <option value="	W"> W</option>
                      <option value="West Bengal">West Bengal</option>
                      
                    </select>
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor="staticEmail"
                    className="col-3 "
                    id="userlable"
                  >
                    City:
                  </label>
                  <div className="col-6" id="usertext">
                    <select id="name3" name="city" value={userData.city} onChange={handleChange}>
                      <option value="A">A</option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Albanie">Albanie</option>
                      <option value="Algérie">Algérie</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="	Angola"> Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="	Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>

                      <option value="Argentina">Argentina</option>
                      <option value="	Armenia"> Armenia</option>

                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="	Bahrain"> Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="	Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>

                      <option value="	Belgium"> Belgium</option>
                      <option value="	Belize"> Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">
                        Bosnia and Herzegovina
                      </option>
                      <option value="	Botswana"> Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="	Brunei Darussalam">
                        {" "}
                        Brunei Darussalam{" "}
                      </option>
                      <option value="Bulgaria">Bulgaria</option>

                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="	Burundi"> Burundi</option>

                      <option value="	Cambodia"> Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="	Cayman Islands"> Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">
                        {" "}
                        Christmas Island
                      </option>
                      <option value="Cocos (Keeling) Islands">
                        Cocos (Keeling) Islands
                      </option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo"> Congo</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Côte D'ivoire">Côte D'ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="	Cuba"> Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="	Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica"> Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="chennai">chennai</option>
                      
                    </select>
                  </div>
                </div>

                <div className=" row">
                  <label
                    htmlFor="inputPassword"
                    className="col-3 "
                    id="userlable"
                  >
                    Pin Code:
                  </label>
                  <div className="col-6" id="usertext">
                    <input
                      type="text"
                      name="postal_code"
                      id="upusertext4"
                      value={userData.postal_code}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                </div>

                <div className="row">
                  <label
                    htmlFor="inputPassword"
                    className="col-3 "
                    id="userlable"
                  >
                    Address:
                  </label>
                  <div className="col-6" id="usertext">
                    <textarea
                      cols="15"
                      text="TARDEO"
                      name="address"
                      id="name4"
                      rows="2"
                      value={userData.address}
                      onChange={handleChange}
                    />
                  </div>
                </div>
               
                <div className="row">
                  <label
                    htmlFor="inputPassword"
                    className="col-3 "
                    id="userlable"
                  >
                    Email ID:
                  </label>
                  <div className="col-6" id="usertext">
                    <input type="email" name="email" id="upusertext5" value={agentLogin} disabled/>
                  </div>
                </div>

                <div className="row">
                  <label
                    htmlFor="staticEmail"
                    className="col-3 "
                    id="userlable"
                  >
                    Occupation:
                  </label>
                  <div className="col-6" id="usertext">
                    <select id="name3" name="occupation" value={userData.occupation} onChange={handleChange}>
                      <option value="Accountant">Accountant</option>
                      <option value="Agricultural Engineer">
                        Agricultural Engineer
                      </option>
                      <option value="Data entry operation">
                        Data entry operation
                      </option>
                      <option value="Aeroplane Pilot">Aeroplane Pilot</option>
                      <option value="Carpenter">Carpenter</option>
                      <option value="	Chef"> Chef</option>
                      <option value="Chemical Engineer">
                        Chemical Engineer
                      </option>
                      <option value="	Civil Engineer">Civil Engineer</option>
                      <option value="Developer Programmer">
                        Developer Programmer
                      </option>

                      <option value="Electrical Engineer">
                        Electrical Engineer
                      </option>
                      <option value="	Insurance Agent"> Insurance Agent</option>

                      <option value="Photographer">Photographer</option>
                      <option value="IT">IT</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="staticEmail"
                    className="col-3 "
                    id="userlable"
                  >
                    Member Type:
                  </label>
                  <div className="col-6" id="usertext">
                    <select disabled id="name2" name="memberType" value={userData.memberType} onChange={handleChange}>
                      <option value="A">A</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 "></div>
                  <div className="col-9">
                    <div className="row" id="webbutton">
                      <div className="col-3 " id="upbutton">
                        <button
                          type="submit"
                          id="button5"
                          
                        >
                          Update
                        </button>
                      </div>
                      <div className="col-3 " id="upbutton1">
                        <button id="button6" onClick={handleResetClick}>
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default UpdateProfile;
