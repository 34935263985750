import React from 'react'
import './NavMid.css'
import { sizing } from '@mui/system'
const NavMid = () => {
  return (
    <div>
        <div className="navigation4">
        <ul>
          <li>
            <a href="#">About Us</a>
          </li>
          <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
          <li>
            <a href="https://www.google.com/search?q=funrep+vip+logo+image+website&tbm=isch&ved=2ahUKEwjJhPPqi7mAAxX6z6ACHRkyCj8Q2-cCegQIABAA&oq=funrep+vip+logo+image+website&gs_lcp=CgNpbWcQAzoECCMQJ1DaB1j2GmCFHmgAcAB4AIAB_AGIAZYOkgEFMC40LjWYAQCgAQGqAQtnd3Mtd2l6LWltZ8ABAQ&sclient=img&ei=lrzHZMnhE_qfg8UPmeSo-AM&bih=739&biw=1536">MGM</a>
          </li>
          <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
          <li>
            <a href="#">Tutorials</a>
          </li>
          <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
          <li>
            <a href="#">FAQ's</a>
          </li>
          <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
          <li>
            <a href="#">Downloads</a>
          </li>
          <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
          <li>
            <a href="#">Contact Us</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default NavMid