
// import { Link } from "react-router-dom";
// import React, { useState } from "react";
// function AppSideber() {
//   const tokenData = sessionStorage.getItem("token");
//   if (!tokenData) {
//     sessionStorage.removeItem("token");
//     window.location.reload();
//   }
import axios from "axios";
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './Navbar/NavBot/NavBot.css'
import '../../views/points/game.css'
import NavDropdown from 'react-bootstrap/NavDropdown';
const AppSideber = () => {

  let apiBaseURL = "https://royalluck.club:5000";
const[dropdown,setDropdown]=useState(false);
  const [show, setShow] = useState(false);
 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
const handledropdown=()=>{
  setDropdown(!dropdown);
}
//   const logoutUser = async () =>{
//     localStorage.removeItem('token-info');
// }














// function playGame(){
//   alert("Use Microsoft InternetExplorer to play Game")
// }



 useEffect(() => {
     console.log("useEffect is called")
    const tokenData = sessionStorage.getItem('token');

    if (!tokenData) {
      sessionStorage.removeItem("token");
      window.location.reload();
    }

  


    // if(!isLoggedIn){
    //   return null;
    // }
    //get email from localstorage
    const loginEmail = sessionStorage.getItem('loginEmail');
    const roleId = sessionStorage.getItem('roleId');
     
  if (roleId == 0) {
    
    document.getElementById("Pinpassuser").style.display = "visible";
    document.getElementById("pinpass").style.display = "none"; 

} else  {
  
  document.getElementById("Pinpassuser").style.display = "none";
  document.getElementById("pinpass").style.display = "visible"; 
 
} 



  }, []);
















  const[isNavbarVisible,setIsNavbarVisible]=useState(true);
  const history=useHistory();
  const currentRoute=history.location.pathname;
  const routesToHideNavbar=['/LogOut'];
  const shouldHideNavbar=routesToHideNavbar.includes(currentRoute);
  if(shouldHideNavbar){
    return(null)
  }

  const logoutUser = async () =>{
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("loginEmail");
    sessionStorage.removeItem("roleId");
    window.location.href='/';
    window.location.reload();
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
}



  
  // const handleLogout=()=>{
  //   setIsNavbarVisible(false);
  //   localStorage.setItem('navbarVisible','false');
  // }
// useEffect(()=>{
//   const storedNavbarVisiblity = localStorage.getItem('navbarVisible');
//   if(storedNavbarVisiblity ==='false'){
//     setIsNavbarVisible(false);
//   }
// },[]);
   return (
  
  <div >
    {isNavbarVisible &&(
  <div className="navigation1">
  <ul>
    <li>
    <Link to="/" className="nav-link active" >
                
                <p >Manage My Points   </p>
              </Link>
    </li>
    <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
    <li>
    <Link to="/ChildRegistration" className="nav-link active" >
                
                <p >Child Registration   </p>
              </Link>
    </li>
    <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
    <li>
    <Link to="/ResetUserPassword" className="nav-link active" >
                
                <p >Change Password   </p>
              </Link>
    </li>
    {/* <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
    <li>
    <Link to="/ChangePin" className="nav-link active" >
                
                <p >PIN  </p>
              </Link>
    </li> */}
    <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
    <li>
    <Link to="/UpdateProfile" className="nav-link active" >
                
                <p >Update Profile   </p>
              </Link>
    </li>
    <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
   <li className='dropdown' onClick={handledropdown}>
   <Link to="#" className="nav-link active">
                
                <p >Draw Details   </p>
              </Link>
              {dropdown && (
                <ul className='dropdown-menu'>
                  <li>
                    <Link to="/FunRoullet" className="nav-link active">
                    Fun Roulette
                    </Link>
                  </li>
                  <br/>
                  <li>
                    <Link to="/FunTarget" className="nav-link active">
                    Fun Target
                    </Link>
                  </li>
                  <br/>
                  <li>
                    <Link to="/TripleFun" className="nav-link active">
                    TripleFun
                    </Link>
                  </li>
                  <br/>
                  <li>
                    <Link to="/FunAB" className="nav-link active">
                    Fun AndarBahar
                    </Link>
                  </li>
                  <br/>
                  <li>
                    <Link to="/SevenUp" className="nav-link active">
                    Seven Up Down
                    </Link>
                  </li>
                </ul>
              )}
   </li>
     
    {/* <li>
<Link to="/Gamerecords" className="nav-link active"  >
                
                <p style={{color:"white",fontWeight:"bold"}}>Draw Details   </p>
              </Link>
    </li> */}
    {/* <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
    <li>
    <Link to="#" className="nav-link active" >
                
                <p >Download Patch   </p>
              </Link>
    </li> */}
    {/* <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
    <li>
    <Link onClick={() => playGame()} className="nav-link active" >
                
                <p >Play Games    </p>
              </Link>
    </li> */}
    <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
    <li id="pinpass">
    <Link to="/PinPassword" className="nav-link active" >
                
                <p >Pin & Password   </p>
              </Link>
    </li>
   
   
    <li id="Pinpassuser">
    <Link  to="/Pinpassuser"  className="nav-link active" onClick={handleShow} >
                
                <p >Pin & Password   </p>
              </Link>
    </li>
    <span style={{color:"white"}}>&nbsp;|&nbsp;</span> 
    <li>
<Link   onClick={() => logoutUser()} to="/" > 
                                <span  >  Logout </span>
                            </Link>
{/* <Link  to="/LogOut" onClick={handleLogout}  className="nav-link active" > 
                                <span  >  Logout </span>
                            </Link> */}
    </li>
  </ul>
  
</div>
)}
<>
<Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>You Are Not Authorized To Access This Web Page!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>  

      
    </>
</div>
   
 
      
           
            
            
           

                        
        
    
    
    
  );
}
export default AppSideber;
