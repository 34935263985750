import React, { useState, useEffect } from "react";
import MaterialTable, {
  MTableToolbar,
  MTableHeader,
  MTablePagination,
  MTableBodyRow,
  MTableCell,
  MTableBody,
} from "material-table";

import "../../style/Contact.css";
import axios from "axios";
// import { apiBaseURL } from "../../config";
import moment from "moment";

import Swal from "sweetalert2";
function Receivable() {
  let apiBaseURL = "https://royalluck.club:5000";

  //let apiBaseURL  = "https://royalluck.club:5000"

  const [data, setData] = useState([]);

  //get Agents
  const getPlayers = async () => {
    await axios
      .get(`${apiBaseURL}/user/PointHistory`)
      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          const result = response.data.data.filter((item) => {
            return item.email != "admin@admin.com";
          });
          setData(result);

          // setData(response.data.data);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
  };

  //get Agents
  const changeStatus = async (distributor, status, message) => {
    Swal.fire({
      title: `Are you sure? Want to ${message}`,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${message} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        let statusCode;
        if (status === 1) {
          statusCode = 0;
        } else {
          statusCode = 0;
        }
        let updateData = {
          active: statusCode,
          distributor_id: distributor,
        };
        axios
          .put(`${apiBaseURL}/api/users/changeStatusDistributor`, updateData)
          .then(function (response) {
            if (response.data.status === 200) {
              getPlayers();
              Swal.fire(
                `${response.data.message}!`,
                `User status have been ${response.data.message}`,
                "success"
              );
            } else {
              Swal.fire(`${response.data.message}!`, "error");
            }
          })
          .catch(function (error) {
            Swal.fire(`Something Went wrong!`, "error");
          });
      }
    });
  };

  //get Agents
  const deleteUser = async (distributor) => {
    Swal.fire({
      position: "top-start",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${apiBaseURL}/api/users/deleteDistributor/${distributor}`)
          .then(function (response) {
            console.log(response);
            if (response.data.status === 200) {
              getPlayers();
              Swal.fire(
                `${response.data.message}!`,
                `User have been ${response.data.message}`,
                "success"
              );
            } else {
              Swal.fire(`${response.data.message}!`, "error");
            }
          })
          .catch(function (error) {
            Swal.fire(`Something Went wrong!`, "error");
          });
      }
    });
  };
  const columns = [
    //   { title: "Sl No.", render: rowData => rowData.tableData.id + 1 },
    // { title: "Sl No.", field: "id" },
    //  { title: "Player Id", field: "player_id" },

    //   { title: "Fullname", field: "first_name" },
    //   { title: "Email", field: "email" },
    //   { title: "Point", field: "point" },

    //  { title: "Password", field: "password" }, // { title: "Status", field: "status" }
    //  { title: "Username", field:"username"  },
    //   { title: "FirstName",field:"first_name" },
    //   { title: "LastName",  field:"last_name" },
    //   { title: "Phone", field:"phone" },
    //   { title: "Email", field:"email"}
    { title: "Point", field: "point" },
    { title: "From User", field: "sender" },
    { title: " To User ", field: "receiver" },
    {
      title: "Transaction Date",
      render: (rowData) =>
        rowData.createdat
          ? moment(rowData.createdat).format("YYYY-M-D h:mm:ss")
          : "Not login yet",
      filtering: true,
    },
  ];

  useEffect(() => {
    getPlayers();
    fetch("/api/transaction")
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);

  return (
    <div
      className="card card-outline "
      style={{
        width: "700px",
        height: "10px",
        marginLeft: "200px",
        marginTop: "50px",
      }}
    >
      <MaterialTable
        title="Receivable Notification"
        data={data}
        columns={columns}
        options={{
          pageSize: 500,
          search: false,

          toolbar: true,
          header: true,
          thirdSortClick: false,

          headerStyle: {
            background: "red",
            position: "sticky",
            top: "0",
          },
          tableLayout: "fixed",
          maxBodyHeight: "300px",

          headerStyle: {
            background: "#b2994e",
            fontStyle: "italic",
            border: "1px solid black",
          },
          padding: "dense",
          cellStyle: {
            whiteSpace: "nowrap",
          },
          tollbarStyle: { width: "50%" },
          headerStyle: {
            whiteSpace: "nowrap",
            height: 20,
            maxHeight: 20,
            padding: 0,
            background: "#b2994e",
            border: "1px solid black",
            borderTop: "6px solid white",
            borderBottom: "6px solid white",
          },

          rowStyle: {
            width: 20,
            maxWidth: 20,
            height: 20,
            maxHeight: 20,
            padding: 0,
          },
          toolbarStyle: {
            height: 10,
            maxHeight: 10,
          },

          rowStyle: { border: "1px solid black" },
          rowStyle: (data, index) =>
            index % 2 == 1 ? { background: "#F2D2BD" } : null,
          cellStyle: { border: "1px solid black", alignItems: "center" },
          paging: false,
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ background: "#b2994e", borderSpacing: "5px" }}>
              <MTableToolbar {...props} />
            </div>
          ),
          Pagination: (props) => (
            <div style={{ backgroundColor: "#b2994e" }}>
              <MTablePagination {...props} />
            </div>
          ),
          Row: (props) => (
            <div style={{}}>
              <MTableBodyRow {...props} />
            </div>
          ),

          Header: (props) => (
            <div>
              <MTableHeader {...props} />
            </div>
          ),
          Body: (props) => (
            <div style={{}}>
              <MTableBody {...props} />
            </div>
          ),
        }}
        /*  actions={[
          (rowData) => {
            return {
              icon: rowData.IsBlocked === 1 ? "block" : "settingsremote",
              tooltip: rowData.IsBlocked === 1 ? "Blocked" : "Active",
              onClick: (event, row) => changeStatus(row.distributor_id,row.active,`${row.active === 0 ? "block" : "unblock"}`),
            };
          },
          {
            icon: "delete",
            tooltip: "Remove Distributor",
            onClick: (event, rowData) => deleteUser(rowData.distributor_id),
          },
        ]} */
      />
    </div>
  );
}
export default Receivable;
