import React ,{useState,useEffect} from "react";
import NavTop from "./layouts/Navbar/NavbarTop/NavTop";
import NavMid from "./layouts/Navbar/NavbarMid/NavMid";
import NavBot from "./layouts/Navbar/NavBot/NavBot";


import { BrowserRouter as Router,Switch,Route } from "react-router-dom/cjs/react-router-dom.min";
// import Header from "./Dashboard/Header";
// import SideMenu from "./Dashboard/SideMenu";
// import AppHeader from "./layouts/AppHeader";
import AppSideber from "./layouts/AppSideber"; 

// import Logo from "./layouts/Navbar/Logo";
import AppContents from "./layouts/AppContents";
import { createContext } from "react";
import { isLastDayOfMonth } from "date-fns";
import WithNav from "./layouts/Navbar/WithNav";
import Yellow from "./layouts/Navbar/Yellow";



// import Footer from "./Dashboard/Footer";

const DashboardLayout = () => {
// const [isLoggedIn,setIsLoggedIn]=useState(true)
// const handleLogout=()=>{
//   setIsLoggedIn(false)
//   localStorage.setItem('isLoggedIn','false')
// }
// useEffect(()=>{
//   localStorage.setItem('isLoggedIn',isLoggedIn)
// },[isLoggedIn])
  return (
    <>
    
    <NavTop/>
     <NavMid/>
   
  
    
     <Yellow/>
    <AppSideber/>
     {/* {isLoggedIn && <AppSideber isLoggedIn={isLoggedIn} handleLogout={handleLogout}/>} */}
   
    
  
   
    {/* <Logo/> */}
  
    {/* <AppFooter /> */}
    {/* <NavBot/> */}

      {/* <AppHeader /> */}
      
      <div className="content-wrapper">
      
        <AppContents />
       
      </div>
      <>
      
      </>
    </>
  );
};

export default DashboardLayout;
