import React, { useState, useEffect } from "react";
import MaterialTable, {
  MTableHeader,
  MTableToolbar,
  MTablePagination,
  MTableBodyRow,
  MTableCell,
  MTableBody,
} from "material-table";
import "../../style/Contact.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
// import { apiBaseURL } from "../../config";
import moment from "moment";
let apiBaseURL = "https://royalluck.club:5000";

// import { authToken } from "../../authToken";

function SevenUpGameHistory() {
  const [data, setData] = useState([]);
  const TableCellStyle = { borderLeft: "1px solid #e5e5e5" };
  // get Agents
  const gameReports = async () => {
    await axios
      .get(`${apiBaseURL}/user/SevenUpGamePlayHistory`)
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        }
      })
      .catch(function (error) {});
  };

  //get Agents

  const columns = [
    { title: "Serial No", render: (rowData) => rowData.tableData.id + 1 },
    //  { title: "Player ID", field: "playername" },

    { title: "RoundCount", field: "RoundCount" },
    { title: "Dice1", field: "dice1" },
    { title: "Dice2", field: "dice2" },

    { title: "WinType", field: "wintype" },

    //{ title: "Win_finalNo", field: "Win_finalNo" },

    {
      title: "Date & Time",
      render: (rowData) =>
        moment(rowData.playedtime).format("DD-MM-YYYY h:mm:ss "),
    },
  ];

  useEffect(() => {
    gameReports();
  }, []);
  return (
    <>
      <div style={{ width: "700px" }}>
        <MaterialTable
          title="Game Plays History"
          data={data}
          columns={columns}
          // options={{
          //   pageSize: 500,
          //   search: false,

          //   toolbar: true,
          //   header:true,
          //   thirdSortClick: false,

          //   headerStyle: {
          //     position: "sticky",
          //     top: "0"
          //   }, tableLayout: "fixed",
          //   maxBodyHeight: "300px",headerStyle:{background:"#b2994e",fontStyle:'italic', border:'1px solid black',
          //   } ,

          //   rowStyle:{border:'1px solid black'},
          //     rowStyle:(data,index)=>index%2==1?{background:"#F2D2BD",}:null,
          //     cellStyle:{border:'1px solid black',alignItems:"center" , },paging: false,

          // }}
          options={{
            pageSize: 500,
            search: false,

            toolbar: true,
            header: true,
            thirdSortClick: false,

            headerStyle: {
              background: "red",
              position: "sticky",
              top: "0",
            },
            tableLayout: "fixed",
            maxBodyHeight: "300px",

            headerStyle: {
              background: "#b2994e",
              fontStyle: "italic",
              border: "1px solid black",
            },
            padding: "dense",
            cellStyle: {
              whiteSpace: "nowrap",
            },
            tollbarStyle: { width: "50%" },
            headerStyle: {
              whiteSpace: "nowrap",
              height: 20,
              maxHeight: 20,
              padding: 0,
              background: "#b2994e",
              border: "1px solid black",
              borderTop: "6px solid white",
              borderBottom: "6px solid white",
            },

            rowStyle: {
              width: 20,
              maxWidth: 20,
              height: 20,
              maxHeight: 20,
              padding: 0,
            },
            toolbarStyle: {
              height: 10,
              maxHeight: 10,
            },

            rowStyle: { border: "1px solid black" },
            rowStyle: (data, index) =>
              index % 2 == 1 ? { background: "#F2D2BD" } : null,
            cellStyle: { border: "1px solid black", alignItems: "center" },
            paging: false,
          }}
          components={{
            Toolbar: (props) => (
              <div style={{ background: "#b2994e", borderSpacing: "5px" }}>
                <MTableToolbar {...props} />
              </div>
            ),
            Pagination: (props) => (
              <div style={{ backgroundColor: "#b2994e" }}>
                <MTablePagination {...props} />
              </div>
            ),
            Row: (props) => (
              <div style={{}}>
                <MTableBodyRow {...props} />
              </div>
            ),

            Header: (props) => (
              <div>
                <MTableHeader {...props} />
              </div>
            ),
            Body: (props) => (
              <div style={{}}>
                <MTableBody {...props} />
              </div>
            ),
          }}
        />
      </div>
    </>
  );
}
export default SevenUpGameHistory;
