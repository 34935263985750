import React from 'react'
import "./mem.css"
const MemType = () => {
  return (

    <div className='mems' >
    <center >
   <h1 id="mh">Member Types</h1>
  

<div class="grid">
  <div class="grid-item">Particular</div>
  <div class="grid-item">Type A</div>
  <div class="grid-item">Type B</div>  
  <div class="grid-item">MemberShip Fees</div>
  <div class="grid-item">1000</div>
  <div class="grid-item">1000</div>  
  <div class="grid-item">Free Points</div>
  <div class="grid-item">0</div>
  <div class="grid-item">500</div>  
  <div class="grid-item">Free Gaming Points</div>
  <div class="grid-item">0</div>
  <div class="grid-item">500</div>  

   </div>
   
   </center> 
   <div className='close1'><a href="/ChildRegistration" >Close</a></div>
   
    </div>
    
  )
}

export default MemType