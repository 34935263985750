import React from 'react'
import "./Footer.css"
export default function AppFooter() {
  return (
    <>
    
      
<div className='foot'>
      
        <h6 style={{ color: 'green' }}>For sales / marketing enquiry, please contact on WhatsApp Number: <span style={{ color: 'blue' }}>+91 xxxxxxxxx</span></h6>
        <h6 style={{ color: 'green' }}>सेल्स / मार्केटिंग के लिए, कृपया व्हाट्सएप नंबर पर संपर्क करें: <span style={{ color: 'blue' }}>+91 xxxxxxxxxx</span></h6>
        </div>
     
    </>
  )
}
