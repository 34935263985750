import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Welcome from "./Welcome";
import "./PinPass.css";

const PinPassword = () => {
  const apiBaseURL = "https://royalluck.club:5000";

  const [email, setEmail] = useState(null);
  const [destriData, setDestriData] = useState(null);
  const [destriData1, setDestriData1] = useState([]);
  const [destriData2, setDestriData2] = useState("");
  const [destriData3, setDestriData3] = useState("");
  const [loader, setloader] = useState(false);

  const loginId = sessionStorage.getItem("loginEmail");
  const [formData, setFormData] = useState({
    managerId: loginId,
    MemberId: "",
    required: "Need Pin",
  });
  const clearmemberid = () => {
    setloader(false);
    setDestriData1([]);
    setTimeout(() => {
      setloader(true);
    }, 500);
  };






  // const  changepinpass=()=>{
  //   setloader(false)
  //   var arr=destriData1
  //   for(var i=0;i<arr.length;i++){
  //     arr[i].reset=  Math.floor(1000 + Math.random() * 9000);

  //   }

  //   setDestriData1(arr)
  //   setTimeout(() => {
  //     setloader(true)
  //   }, 500);
  // }

  const changepinpass = async () => {
    setloader(false);
    var arr = destriData1.slice();
    for (var i = 0; i < arr.length; i++) {
      const reset = 1000 + Math.floor(Math.random() * 9000);

      arr[i].reset = reset;

      const postData = {
        email: arr[i].id,
        newpassword: reset,
      };

      try {
        await axios.post(`${apiBaseURL}/auth/changePassword`, postData);
        console.log("Password change successful for", arr[i].id);
      } catch (error) {
        console.error("Error changing password for", arr[i].id, error);
      }
    }

    setDestriData1(arr);

    setTimeout(() => {
      setloader(true);
    }, 500);
  };

  const getPlayerId = async () => {
    await axios
      .post(`${apiBaseURL}/user/getEmailsById`, {
        email: loginId,
      })
      .then((response) => {
        const { masteridUsers, users } = response.data.result;
        const masteridEmails = masteridUsers.map((user) => ({
          email: user.email,
        }));
        const userEmails = users.map((user) => ({
          email: user.email,
        }));
        setEmail([...masteridEmails, ...userEmails]);
      })
      .catch((error) => {
        setEmail([]);
      });
  };

  // const getPlayerId = async () => {
  //   const loginId = sessionStorage.getItem("loginEmail");
  //   if (loginId) {
  //     try {
  //       const response = axios.get(`${apiBaseURL}/user/getEmailsById`, {
  //         email: loginId,
  //       });
  //       const { masteridUsers, users } = response.data.result;
  //       const masteridEmails = masteridUsers.map((user) => ({
  //         email: user.email,
  //       }));
  //       const userEmails = users.map((user) => ({
  //         email: user.email,
  //       }));
  //       setEmail([...masteridEmails, ...userEmails]);
  //     } catch (error) {
  //       setEmail([]);
  //     }
  //   } else {
  //     console.log("Login ID not available. Please log in first.");
  //   }
  // };



  


  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setDestriData2(value);

    console.log(`Selected ${name}: ${value}`);

    setFormData({ ...formData, [name]: value });
  };
  const handleSelectChange1 = (event) => {
    const { name, value } = event.target;
    setDestriData3(value);

    console.log(`Selected ${name}: ${value}`);

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    setloader(false);
    event.preventDefault();
    const { MemberId, required, managerId } = formData;
    var arrlist = destriData1;
    arrlist.push({ id: destriData2, type: destriData3, reset: "" });
    setDestriData1(arrlist);
    console.log(destriData1, "1111");
    try {
      const response = await axios.post(`${apiBaseURL}/user/pinPassword`, {
        managerId,
        MemberId,
        required,
      });

      console.log("Response:", response);
      const status = response.status;

      console.log("Response status:", status);
      if (status === 200) {
        setloader(true);
        Swal.fire("Form submitted successfully!", "success");
      } else {
        setloader(true);

        Swal.fire("Error while submitting the form", "error");
      }
      console.log("After Swal.fire");
    } catch (error) {
      Swal.fire("Network error occurred", "error");
    }
  };
  useEffect(() => {
    getPlayerId();
  }, []);
  useEffect(() => {}, [destriData1]);

  return (
    <div>
      <div className="homepage">
        <Welcome />
        {/* {loader == true ? ( */}
        <div>
          <div className="App">
            <div className="pintab">
              <div className="pintable">
                <div className="row" id="pinpass">
                  <div className="container5">
                    <div className="row justify-content-center" id="pinrow1">
                      <div className="col-4" id="pinbutton1">
                        <button
                          className="button btn-primary"
                          onClick={changepinpass}
                          id="pinbutton11"
                        >
                          Change&nbsp;Pin&nbsp;Password
                        </button>
                      </div>

                      <div className="col-4" id="pinbutton2">
                        <button
                          className="button btn-primary"
                          onClick={clearmemberid}
                          id="pinbutton21"
                        >
                          Reset&nbsp;Member&nbsp;ID
                        </button>
                      </div>
                    </div>

                    <div className="row" id="pinrow2">
                      <div className="col-4" id="pinmem">
                        MemberID
                      </div>
                      <div className="col-4" id="pinreq">
                        Required
                      </div>
                      <div className="col-4" id="pinres">
                        Reset Status
                      </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row" id="pinrow3">
                        {destriData1.map((item, index) => {
                          return (
                            <div className="row" id="pinrow4" key={index}>
                              <div className="col-4" id="pinmem1">
                                {item.id}
                              </div>
                              <div className="col-4" id="pinreq1">
                                {item.type}
                              </div>
                              <div className="col-4" id="pinres1">
                                {item.reset}
                              </div>
                            </div>
                          );
                        })}
                        <div className="row" id="pinrow5">
                          <div className="col-4" id="pindrop1">
                            <select
                              name="MemberId"
                              /*    value={formData.MemberId} */
                              onChange={handleSelectChange}
                            >
                              <option>Please Select</option>
                              {email &&
                                email.map((item, index) => (
                                  <option key={index} value={item.email}>
                                    {item.email}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-4" id="pindrop2">
                            <select
                              name="required"
                              /*  value={formData.required}
                               */ onChange={handleSelectChange1}
                            >
                              <option>Please Select</option>

                              <option value="Need Pin">Need Pin</option>

                              <option value="Need Password">
                                Need Password
                              </option>

                              <option value="Need Both">Need Both</option>
                            </select>
                          </div>
                          <div className="col-4" id="pinbutton3">
                            <button
                              className="button btn-primary"
                              type="submit"
                              id="pinbutton31"
                            >
                              Add Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       {/* ) : (
        ""
    )}   */}
      </div>
    </div>
  );
};

export default PinPassword;
