import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Welcome.css";

const Welcome = () => {
  const agentLogin = sessionStorage.getItem("loginEmail");
  const apiBaseURL = "https://royalluck.club:5000";
  const [playerData, setPlayerData] = useState(null);
  const [Name, setName] = useState(null);
  const [Point, setPoint] = useState(null);
  useEffect(() => {
    const fetchPlayerPoint = async () => {
      let emailId = sessionStorage.getItem("loginEmail");

      try {
        const response = await axios.post(`${apiBaseURL}/user/getPlayerPoint`, {
          email: emailId,
        });

        if (response.status === 200) {
          const responseData = response.data;
          if (responseData && responseData.point !== undefined) {
            setPlayerData(responseData.point);
            console.log("playerData updated:", responseData.point);
          } else {
            console.log("Point data not found in response:", responseData);
          }
        } else {
          console.log("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching player point:", error);
      }
    };
    const getName= async () => {
      let emailId = sessionStorage.getItem("loginEmail");
  
      try {
        const response = await axios.post(`${apiBaseURL}/user/getSuperMasterUserName`, {
          email: emailId,
        });
  
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData && responseData.Name !== undefined) {
            setName(responseData.Name);
            console.log("playerData updated:", responseData.Name);
          } else {
            console.log("Point data not found in response:", responseData);
          }
        } else {
          console.log("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching player point:", error);
      }
    };
  
  // get all point
  
  const getPoint= async () => {
    let emailId = sessionStorage.getItem("loginEmail");
  
    try {
      const response = await axios.post(`${apiBaseURL}/user/getSuperMasterUserPoint`, {
        email: emailId,
      });
  
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData && responseData.Point !== undefined) {
          setPoint(responseData.Point);
          console.log("playerData updated:", responseData.Point);
        } else {
          console.log("Point data not found in response:", responseData);
        }
      } else {
        console.log("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching player point:", error);
    }
  };
    fetchPlayerPoint();
    getPoint();
    getName();
  }, []);

  return (
    <div>
      <div className="welcome">
        <p>Welcome</p>
        <h5>
          <span>{agentLogin}</span>
        </h5>
        <h6>
          Your Current Balance is{" "}
          <span>{Point !== null ? Point : "Loading..."}</span>
        </h6>
      </div>
    </div>
  );
};

export default Welcome;
