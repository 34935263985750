import React from 'react'
import "./yellow.css"
const Yellow = () => {
  return (
    <div>
        <div className="yello"></div> 
        
    </div>
  )
}

export default Yellow