import React, { useState, useEffect } from "react";
import "./user.css";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Welcome from "../settings/Welcome";
function ChildRegistration() {
  //let apiBaseURL = "https://royalluck.club:5000"
 
  let emailId = sessionStorage.getItem("loginEmail");
  let apiBaseURL = "https://royalluck.club:5000";
  const sessionData = sessionStorage.getItem("token");

  const [values, setValues] = useState({
    full_name: "",
    first_name: "",
    last_name: "",
    dobDay: "",
    dobMonth: "",
    dobYear: "",
    Country: "",
    State: "",
    City: "",
    postal_code: "",
    Address: "",
    Phone: "",
    Occupation: "",
    memberType: "",
    email: "",
    password: "",
    confirmPassword: "",
    parentId: "",
  });

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  const [destriData, setDestriData] = useState("");
  const [iscreated, setiscreated] = useState("");
  const value = [true, "sm-down", "md-down", "lg-down", "xl-down", "xxl-down"];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      full_name,
      first_name,
      last_name,
      dobDay,
      dobMonth,
      dobYear,
      Country,
      State,
      City,
      postal_code,
      Address,
      Phone,
      Occupation,
      memberType,
     password,
      confirmPassword,
      } = values;
    const user = {
      full_name,
      first_name,
      last_name,
      dobDay,
      dobMonth,
      dobYear,
      Country,
      State,
      City,
      postal_code,
      Address,
      Phone,
      Occupation,
      memberType,
      email: destriData,
      password,
      confirmPassword,
      parentId: emailId,
    };
    await axios({
      method: "post",
      url: `${apiBaseURL}/auth/ChildSignUp`,
      data: user,
      headers: { Authorization: `Bearer ${sessionData.token}` },
    })
      .then(function (response) {
       
        setiscreated((pre) => !pre);
        if (response.data.status === 201) {
          setValues({
            full_name: "",
            first_name: "",
            last_name: "",
            dobDay: "",
            dobMonth: "",
            dobYear: "",
            Country: "",
            State: "",
            City: "",
            postal_code: "",
            Address: "",
            Phone: "",
            Occupation: "",
            memberType: "",
            email: "",
            password: "",
            confirmPassword: "",
            parentId: "",
          });
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `${response.data.message} !`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          if (response.data.status == 401) {
            sessionStorage.removeItem("token");
            window.location.reload();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "Oops...",
              text: `${response.data.message} !`,
              showConfirmButton: false,
              timer: 1700,
            });
          }
        }
      })
      .catch(function (error) {
        Swal.fire(`Something went wrong!`, "error");
      });
  };
  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };
  //get Agents
  const getAgents = async () => {
    await axios
      .get(`${apiBaseURL}/user/getChildIdData`)
      .then(function (response) {
        if (response.data.status === 200) {
          setDestriData(response.data.data);
        }
      })
      .catch(function (error) {
        Swal.fire(`Something went wrong!`, "error");
      });
  };
  //

 
  useEffect(() => {
    getAgents();
  }, [iscreated]);
  const values1 = [true, "xxl-down"];

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <>
      <div className="homepage">
        <Welcome />
        <div className="row" id="ca">
          <div className="col-md-8">
            <div className="card ">
              <div
                className="card-header"
                style={{ backgroundColor: "darkred" }}
                id="updateheader"
              >
                <h3
                  className="card-title"
                  style={{ color: "white" }}
                  id="updatetitle"
                >
                  :: Child Registration
                </h3>
              </div>
              <div
                className="card-body"
                id="car"
                style={{ background: "#E5E4E2" }}
              >
                <form method="post" onSubmit={handleSubmit}>
                  <div className="row" id="chtable">
                    <label htmlFor="staticEmail" className="col-sm-3">
                      Full Name:
                    </label>
                    <div className="col-sm-6">
                      <input
                        style={{ maxWidth: "50%" }}
                        type="text"
                        // required
                        id="full_name"
                        name="full_name"
                        value={values.full_name}
                        onChange={handleChange("full_name")}
                      />
                    </div>
                  </div>
                  <div className="row" id="chtable1">
                    <label htmlFor="staticEmail" className="col-sm-3">
                      First Name:
                    </label>
                    <div className="col-sm-6">
                      <input
                        style={{ maxWidth: "50%" }}
                        type="text"
                        // required
                        id="first_name"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange("first_name")}
                      />
                    </div>
                  </div>
                  <div className=" row" id="chtable2">
                    <label htmlFor="staticEmail" className="col-sm-3 ">
                      Last Name:
                    </label>
                    <div className="col-sm-6">
                      <input
                        style={{ maxWidth: "50%" }}
                        type="text"
                        // required
                        id="last_name"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange("last_name")}
                      />
                    </div>
                  </div>

                  <div className=" row" id="chtable3">
                    <label htmlFor="staticEmail" className="col-sm-3 ">
                      Date Of Birth:
                    </label>
                    <div className="col-sm-6" style={{ maxWidth: "50%" }}>
                      <select
                        value={values.dobDay}
                        onChange={handleChange("dobDay")}
                        id="dobDay"
                        name="dobDay"
                      >
                        <option value="">Day</option>
                        {Array.from({ length: 31 }, (_, i) => i + 1).map(
                          (day) => (
                            <option key={day} value={day}>
                              {day}
                            </option>
                          )
                        )}
                      </select>
                      <select
                        value={values.dobMonth}
                        onChange={handleChange("dobMonth")}
                        id="dobMonth"
                        name="dobMonth"
                      >
                        <option value="">Month</option>
                        {Array.from({ length: 12 }, (_, i) => i + 1).map(
                          (month) => (
                            <option key={month} value={month}>
                              {month}
                            </option>
                          )
                        )}
                      </select>
                      <select
                        value={values.dobYear}
                        onChange={handleChange("dobYear")}
                        id="dobYear"
                        name="dobYear"
                      >
                        <option value="">Year</option>
                        {Array.from(
                          { length: 100 },
                          (_, i) => new Date().getFullYear() - i
                        ).map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row" id="chtable4">
                    <label htmlFor="staticEmail" className="col-sm-3 ">
                      Country:
                    </label>
                    <div className="col-sm-6" style={{ maxWidth: "50%" }}>
                      <select
                        id="Country"
                        value={values.Country}
                        onChange={handleChange("Country")}
                        name="Country"
                      >
                        <option >select</option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Albanie">Albanie</option>
                        <option value="Algérie">Algérie</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="	Angola"> Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="	Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>

                        <option value="Argentina">Argentina</option>
                        <option value="	Armenia"> Armenia</option>

                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="	Bahrain"> Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="	Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>

                        <option value="	Belgium"> Belgium</option>
                        <option value="	Belize"> Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="	Botswana"> Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="	Brunei Darussalam">
                          {" "}
                          Brunei Darussalam{" "}
                        </option>
                        <option value="Bulgaria">Bulgaria</option>

                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="	Burundi"> Burundi</option>

                        <option value="	Cambodia"> Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="	Cayman Islands"> Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="		Christmas Island">
                          {" "}
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="	Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="	Congo"> Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Côte D'ivoire">Côte D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="	Cuba"> Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="	Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="		Dominica"> Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>

                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="	Eritrea"> Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="	Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">
                          Falkland Islands
                        </option>

                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="	Fiji"> Fiji</option>

                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="	Gabon"> Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="	Georgia">Georgia</option>
                        <option value="Germany">Germany</option>

                        <option value="	Ghana"> Ghana</option>
                        <option value="	Gibraltar"> Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="	Botswana"> Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="	Brunei Darussalam">
                          {" "}
                          Brunei Darussalam{" "}
                        </option>
                        <option value="Guatemala">Guatemala</option>

                        <option value="Guinea">Guinea</option>
                        <option value="	Guinea-Bissau"> Guinea-Bissaui</option>

                        <option value="	Guyana"> Guyana</option>
                      </select>
                    </div>
                  </div>
                  <div className="row" id="chtable5">
                    <label htmlFor="staticEmail" className="col-sm-3 ">
                      State:
                    </label>
                    <div className="col-sm-6" style={{ maxWidth: "50%" }}>
                      <select
                        id="State"
                        value={values.State}
                        onChange={handleChange("State")}
                        name="State"
                      ><option >select</option>
                        <option value="A">A</option>
                        <option value="	Andhra Pradesh"> Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="	Assam"> Assam</option>
                        <option value="B">B</option>
                        <option value="	Bihar"> Bihar</option>
                        <option value="C">C</option>
                        <option value="		Chhattisgarh"> Chhattisgarh</option>
                        <option value="G">G</option>
                        <option value="	Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>

                        <option value="H">H</option>
                        <option value="	Haryana"> Haryana</option>

                        <option value="	Himachal Pradesh">
                          {" "}
                          Himachal Pradesh
                        </option>
                        <option value="	J"> J</option>
                        <option value="	Jammu and Kashmir">
                          {" "}
                          Jammu and Kashmir
                        </option>
                        <option value="	Jharkhand"> Jharkhand</option>
                        <option value="	K"> K</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="	M"> M</option>
                        <option value="		Madhya Pradesh"> Madhya Pradesh</option>
                        <option value="	Maharashtra"> Maharashtra</option>
                        <option value="		Manipur"> Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>

                        <option value="	Mizoram"> Mizoram</option>
                        <option value="	N"> N</option>
                        <option value="		Nagaland"> Nagaland</option>
                        <option value="	O"> O</option>
                        <option value="	Odisha"> Odisha</option>
                        <option value="	P"> P</option>
                        <option value="	Punjab"> Punjab</option>
                        <option value="	R"> R</option>
                        <option value="	Rajasthan"> Rajasthan</option>
                        <option value="	S"> S</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="	T"> T</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="		Telangana"> Telangana</option>
                        <option value="	Tripura"> Tripura</option>
                        <option value="	U"> U</option>
                        <option value="Uttar Pradesh"> Uttar Pradesh </option>
                        <option value="	Uttarakhand"> Uttarakhand</option>
                        <option value="	W"> W</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                    </div>
                  </div>
                  <div className="row" id="chtable6">
                    <label htmlFor="staticEmail" className="col-sm-3 ">
                      City:
                    </label>
                    <div className="col-sm-6" style={{ maxWidth: "50%" }}>
                      <select
                        id="City"
                        value={values.City}
                        onChange={handleChange("City")}
                        name="City"
                      >
                        <option value="A">A</option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Albanie">Albanie</option>
                        <option value="Algérie">Algérie</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="	Angola"> Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="	Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>

                        <option value="Argentina">Argentina</option>
                        <option value="	Armenia"> Armenia</option>

                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="	Bahrain"> Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="	Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>

                        <option value="	Belgium"> Belgium</option>
                        <option value="	Belize"> Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="	Botswana"> Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="	Brunei Darussalam">
                          {" "}
                          Brunei Darussalam{" "}
                        </option>
                        <option value="Bulgaria">Bulgaria</option>

                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="	Burundi"> Burundi</option>

                        <option value="	Cambodia"> Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="	Cayman Islands"> Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="		Christmas Island">
                          {" "}
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="	Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="	Congo"> Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Côte D'ivoire">Côte D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="	Cuba"> Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="	Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="		Dominica"> Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="row" id="chtable7">
                    <label htmlFor="inputPassword" className="col-sm-3 ">
                      Pin Code:
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name="postal_code"
                        id="postal_code"
                        style={{ maxWidth: "50%" }}
                        value={values.postal_code}
                        onChange={handleChange("postal_code")}
                      />
                    </div>
                  </div>

                  <div className="row" id="chtable8">
                    <label htmlFor="inputPassword" className="col-sm-3 ">
                      Address:
                    </label>
                    <div className="col-sm-6">
                      <textarea
                        cols="10"
                        style={{ maxWidth: "50%" }}
                        rows="3"
                        name="Address"
                        id="Address"
                        value={values.Address}
                        onChange={handleChange("Address")}
                      />
                    </div>
                  </div>
                  <div className="row" id="chtable9">
                    <label
                      id="phone"
                      htmlFor="inputPhone"
                      className="col-sm-3 "
                    >
                      Phone:
                    </label>
                    <div className="col-sm-6">
                      <input
                        style={{ maxWidth: "50%" }}
                        type="number"
                        name="phone"
                        id="phone1"
                        value={values.Phone}
                        onChange={handleChange("Phone")}
                      />
                    </div>
                  </div>
                  <div className="row" id="chtable10">
                    <label htmlFor="inputPassword" className="col-sm-3 ">
                      Email ID:
                    </label>
                    <div className="col-sm-6">
                      <input
                        style={{ maxWidth: "50%" }}
                        type="text"
                        id="email1"
                        disabled
                        value={destriData}
                      />
                    </div>
                  </div>

                  <div className="row" id="chtable11">
                    <label htmlFor="staticEmail" className="col-sm-3 ">
                      Occupation:
                    </label>
                    <div className="col-sm-6">
                      <select
                        id="Occupation"
                        value={values.Occupation}
                        onChange={handleChange("Occupation")}
                        name="Occupation"
                      >
                        <option >select</option>
                        <option value="Accountant">Accountant</option>
                        <option value="Agricultural Engineer">
                          Agricultural Engineer
                        </option>
                        <option value="Data entry operation">
                          Data entry operation
                        </option>
                        <option value="Aeroplane Pilot">Aeroplane Pilot</option>
                        <option value="Carpenter">Carpenter</option>
                        <option value="	Chef"> Chef</option>
                        <option value="Chemical Engineer">
                          Chemical Engineer
                        </option>
                        <option value="	Civil Engineer">Civil Engineer</option>
                        <option value="Developer Programmer">
                          Developer Programmer
                        </option>

                        <option value="Electrical Engineer">
                          Electrical Engineer
                        </option>
                        <option value="	Insurance Agent">
                          {" "}
                          Insurance Agent
                        </option>

                        <option value="Photographer">Photographer</option>
                        <option value="IT">IT</option>
                      </select>
                    </div>
                  </div>
                  <div className="row" id="chtable12">
                    <label htmlFor="staticEmail" className="col-sm-3 ">
                      Member Type:
                    </label>
                    <div className="col-sm-4">
                      <select
                        id="memberType"
                        value={values.memberType}
                        onChange={handleChange("memberType")}
                        name="memberType"
                      ><option >A</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                      </select>
                    </div>
                    <div className="col-sm-5">
                      <a href="/MemType">What's Member Type? </a>
                    </div>
                  </div>

                  <div className="row" id="chtable13">
                    <label htmlFor="inputPassword" className="col-sm-3 ">
                      Password:
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange("password")}
                        id="password1"
                        style={{ maxWidth: "50%" }}
                        requiredvalue="12"
                      />
                    </div>
                  </div>
                  <div className="form-group row" id="chtable14">
                    <label htmlFor="inputPassword" className="col-sm-3 ">
                      Confirm Password:
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange("confirmPassword")}
                        id="confirmPassword"
                        style={{ maxWidth: "50%" }}
                        requiredvalue="12"
                      />
                    </div>
                  </div>
                  <div class="form-check" id="childcheck">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck4"
                    />
                    <label
                      class="form-check-label"
                      for="exampleCheck1"
                      id="agree"
                    >
                      Yes I agree to the <a href="">Terms and Conditions</a>
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-3 "></div>
                    <div className="col-9">
                      <div className="row">
                        <div className="col-3 " id="childbutton">
                          <button type="onSubmit" id="chbutton5" >
                            Submit
                          </button>
                        </div>
                        <div className="col-3 " id="childbutton1">
                          <button id="chbutton6">Reset</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="fullscreen" style={{ backgroundColor: "red" }}>
          <Modal
            show={show}
            fullscreen={fullscreen}
            onHide={() => setShow(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>Modal body content</Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}
export default ChildRegistration;
