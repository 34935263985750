// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.foot{
    margin-top: 20px;
}
.foot>h6{
    
    font-size: 15px;
    margin-left: 150px;
}
@media(min-width:1026px){
.foot>h6{
    font-size: 15px;
    margin-left: 150px;
}
}
@media(max-width:1025px){
.foot>h6{
    font-size: 12px;
    margin-left: 50px;
}
}
@media(max-width:993px){
.foot>h6{
    font-size: 12px;
    margin-left: 220px;
}
}
@media(max-width:678px){
    .foot{
        margin-top: -45px;
    }
.foot>h6{
    font-size: 8px;
    margin-left: 100px;
}
}
@media(max-width:415px){
.foot>h6{
    font-size: 8px;
    margin-left: 50px;
   
}
}
@media(max-width:376px){
.foot>h6{
    font-size: 7px;
   
  
   
}
}
@media(max-width:281px){
.foot>h6{
    font-size: 5px;
   
  
   
}
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/Footer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;;IAEI,eAAe;IACf,kBAAkB;AACtB;AACA;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;AACA;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;AACA;AACA;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;AACA;IACI;QACI,iBAAiB;IACrB;AACJ;IACI,cAAc;IACd,kBAAkB;AACtB;AACA;AACA;AACA;IACI,cAAc;IACd,iBAAiB;;AAErB;AACA;AACA;AACA;IACI,cAAc;;;;AAIlB;AACA;AACA;AACA;IACI,cAAc;;;;AAIlB;AACA","sourcesContent":[".foot{\r\n    margin-top: 20px;\r\n}\r\n.foot>h6{\r\n    \r\n    font-size: 15px;\r\n    margin-left: 150px;\r\n}\r\n@media(min-width:1026px){\r\n.foot>h6{\r\n    font-size: 15px;\r\n    margin-left: 150px;\r\n}\r\n}\r\n@media(max-width:1025px){\r\n.foot>h6{\r\n    font-size: 12px;\r\n    margin-left: 50px;\r\n}\r\n}\r\n@media(max-width:993px){\r\n.foot>h6{\r\n    font-size: 12px;\r\n    margin-left: 220px;\r\n}\r\n}\r\n@media(max-width:678px){\r\n    .foot{\r\n        margin-top: -45px;\r\n    }\r\n.foot>h6{\r\n    font-size: 8px;\r\n    margin-left: 100px;\r\n}\r\n}\r\n@media(max-width:415px){\r\n.foot>h6{\r\n    font-size: 8px;\r\n    margin-left: 50px;\r\n   \r\n}\r\n}\r\n@media(max-width:376px){\r\n.foot>h6{\r\n    font-size: 7px;\r\n   \r\n  \r\n   \r\n}\r\n}\r\n@media(max-width:281px){\r\n.foot>h6{\r\n    font-size: 5px;\r\n   \r\n  \r\n   \r\n}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
