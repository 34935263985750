import React, { useState, useEffect } from "react";
import MaterialTable, {
  MTableToolbar,
  MTableHeader,
  MTablePagination,
  MTableBodyRow,
  MTableCell,
  MTableBody,
} from "material-table";

import "../../style/Contact.css";
import axios from "axios";
// import { apiBaseURL } from "../../config";
import moment from "moment";

import Swal from "sweetalert2";
let apiBaseURL = "https://royalluck.club:5000";

function PlayersList() {
  const [data, setData] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);

  //get Agents
  const getPlayers = async () => {
    await axios
      .get(`${apiBaseURL}/user/getPlayer`)
      .then(function (response) {
        if (response.data.status === 200) {
          console.log(response.data, "data");
          const result = response.data.data.filter((item) => {
            return item.email !== "admin@admin.com";
          });
          setData(result);
          // setData(response.data.data);
        }
      })
      .catch(function (error) {
        // history.push("/login")
      });
  };

  const columns = [
    { title: "Sl No.", render: (rowData) => rowData.tableData.id + 1 },
    { title: "Fullname", field: "first_name" },
    { title: "Email", field: "email" },
    { title: "Point", field: "point" },
    { title: "Password", field: "password" },
    {
      title: "",
      render: (rowData) => (
        <button
          className="btn btn-danger ml-2"
          onClick={() =>
            handleEdit(rowData, apiBaseURL, getPlayers, rowData.first_name)
          }
        >
          Edit
        </button>
      ),
    },
  ];

  useEffect(() => {
    getPlayers();
  }, []);

  return (
    <div className="card card-outline card-info">
      <MaterialTable
        title="Players List"
        data={data}
        columns={columns}
        options={{
          actionsColumnIndex: -1,
          pageSize: 5,
          search: false,

          toolbar: true,
          header: true,
          thirdSortClick: false,

          headerStyle: {
            background: "red",
            position: "sticky",
            top: "0",
          },
          tableLayout: "fixed",
          maxBodyHeight: "300px",

          headerStyle: {
            background: "#b2994e",
            fontStyle: "italic",
            border: "1px solid black",
          },
          tollbarStyle: { width: "50%" },
          headerStyle: {
            whiteSpace: "nowrap",
            height: 20,
            maxHeight: 20,
            padding: 0,
            background: "#b2994e",
            border: "1px solid black",
            borderTop: "6px solid white",
            borderBottom: "6px solid white",
          },

          rowStyle: {
            width: 20,
            maxWidth: 20,
            height: 20,
            maxHeight: 20,
            padding: 0,
          },
          toolbarStyle: {
            height: 10,
            maxHeight: 10,
          },

          rowStyle: { border: "1px solid black" },
          rowStyle: (data, index) =>
            index % 2 == 1 ? { background: "#F2D2BD" } : null,
          cellStyle: { border: "1px solid black", alignItems: "center" },
          paging: false,
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ background: "#b2994e", borderSpacing: "5px" }}>
              <MTableToolbar {...props} />
            </div>
          ),
          Pagination: (props) => (
            <div style={{ backgroundColor: "#b2994e" }}>
              <MTablePagination {...props} />
            </div>
          ),
          Row: (props) => (
            <div style={{}}>
              <MTableBodyRow {...props} />
            </div>
          ),

          Header: (props) => (
            <div>
              <MTableHeader {...props} />
            </div>
          ),
          Body: (props) => (
            <div style={{}}>
              <MTableBody {...props} />
            </div>
          ),
        }}
      />
      {currentRow && (
        <EditForm
          row={currentRow}
          onClose={() => setCurrentRow(null)}
          apiBaseURL={apiBaseURL}
          getPlayers={getPlayers}
        />
      )}
    </div>
  );
}

const handleEdit = async (rowData, apiBaseURL, getPlayers, firstName) => {
  const { id, password } = rowData;
  const newPassword = prompt(
    "Enter new password (leave blank to keep current password)"
  );
  const newFirstName = prompt("Enter new first name", firstName);

  // Check if the user cancelled the prompt for the new first name or new password
  if (newFirstName === null || newPassword === null) {
    return;
  }

  axios
    .put(`${apiBaseURL}/user/updateUser`, {
      id,
      first_name: newFirstName,
      password: newPassword || null,
    })
    .then(function (response) {
      if (response.status === 200) {
        Swal.fire("Success", response.data.message, "success").then(() =>
          getPlayers()
        );
      } else {
        Swal.fire("Error", response.data.message, "error");
      }
    })
    .catch(function (error) {
      Swal.fire("Error", "Something went wrong", "error");
    });
};

function EditForm({ row, onClose, apiBaseURL, getPlayers }) {
  const [firstName, setFirstName] = useState(row.first_name);
  const [password, setPassword] = useState(row.password);

  const handleSave = async () => {
    try {
      const updatedData = {
        id: row.id,
        first_name: firstName,
        password: password,
      };

      await axios.put(`${apiBaseURL}/user/updateUser`, updatedData);

      onClose();
      getPlayers();
      alert("User updated successfully");
    } catch (error) {
      console.error(error);
      alert("Failed to update user");
    }
  };

  return (
    <div>
      <div>
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          id="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <button onClick={onClose}>Cancel</button>
        <button onClick={handleSave}>Save</button>
      </div>
    </div>
  );
}

export default PlayersList;
