// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yello {
   
    float: left;
    width: 100%;
    height: 20px;
    
    position: relative;
    display: block;
    background: purple;
   
  }
  @media (max-width:961px){
      .yello {
 
          float: left;
          width: 100%;
          height: 15px;
          
          position: relative;
          display: block;
          background: purple;
         
        }  
  }
  @media (max-width:641px){
      .yello {
 
          float: left;
          width: 100%;
          height: 10px;
          
          position: relative;
          display: block;
          background: purple;
         
        }    
  }
  @media (max-width:481px){
      .yello {
 
          float: left;
          width: 100%;
          height: 8px;
          
          position: relative;
          display: block;
          background: purple;
         
        }       
  }
  @media (max-width:320px){
      .yello {
 
          float: left;
          width: 100%;
          height: 6px;
          
          position: relative;
          display: block;
          background: purple;
         
        }     
  }`, "",{"version":3,"sources":["webpack://./src/components/layouts/Navbar/yellow.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,WAAW;IACX,YAAY;;IAEZ,kBAAkB;IAClB,cAAc;IACd,kBAAkB;;EAEpB;EACA;MACI;;UAEI,WAAW;UACX,WAAW;UACX,YAAY;;UAEZ,kBAAkB;UAClB,cAAc;UACd,kBAAkB;;QAEpB;EACN;EACA;MACI;;UAEI,WAAW;UACX,WAAW;UACX,YAAY;;UAEZ,kBAAkB;UAClB,cAAc;UACd,kBAAkB;;QAEpB;EACN;EACA;MACI;;UAEI,WAAW;UACX,WAAW;UACX,WAAW;;UAEX,kBAAkB;UAClB,cAAc;UACd,kBAAkB;;QAEpB;EACN;EACA;MACI;;UAEI,WAAW;UACX,WAAW;UACX,WAAW;;UAEX,kBAAkB;UAClB,cAAc;UACd,kBAAkB;;QAEpB;EACN","sourcesContent":[".yello {\r\n   \r\n    float: left;\r\n    width: 100%;\r\n    height: 20px;\r\n    \r\n    position: relative;\r\n    display: block;\r\n    background: purple;\r\n   \r\n  }\r\n  @media (max-width:961px){\r\n      .yello {\r\n \r\n          float: left;\r\n          width: 100%;\r\n          height: 15px;\r\n          \r\n          position: relative;\r\n          display: block;\r\n          background: purple;\r\n         \r\n        }  \r\n  }\r\n  @media (max-width:641px){\r\n      .yello {\r\n \r\n          float: left;\r\n          width: 100%;\r\n          height: 10px;\r\n          \r\n          position: relative;\r\n          display: block;\r\n          background: purple;\r\n         \r\n        }    \r\n  }\r\n  @media (max-width:481px){\r\n      .yello {\r\n \r\n          float: left;\r\n          width: 100%;\r\n          height: 8px;\r\n          \r\n          position: relative;\r\n          display: block;\r\n          background: purple;\r\n         \r\n        }       \r\n  }\r\n  @media (max-width:320px){\r\n      .yello {\r\n \r\n          float: left;\r\n          width: 100%;\r\n          height: 6px;\r\n          \r\n          position: relative;\r\n          display: block;\r\n          background: purple;\r\n         \r\n        }     \r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
